import { SafeHtml } from '@angular/platform-browser';

//START NAVIGATION GEO DATA

export enum ShowOnFloorplanEnum {
  Default,
  None,
  OnlyIcon,
  Name,
  Image,
}

export interface KioskBasicData {
  name: string;
  kioskId: string;
  rootAreaId: number;
  enable: boolean;
}

export interface NavigationGeodataBM {
  typeId: number;
  resourceId?: number | null;
  childAreaId?: number | null;
  layerId: string;
  radius?: number;
  name?: string | null;
  mapName?: string;
  linkToImage?: string | null;
  linkToIcon?: string | null;
  isKiosk: boolean;
  kioskData?: KioskBasicData;
  qrCodeId?: string | null;
  showOnFloorplan?: ShowOnFloorplanEnum;
  geometry: GeometryPointData | GeometryLineStringData | GeometryPolygonData | GeometryDataInfo;
  qrCategoryColor?: string;
}

export interface GeometryPointData {
  type: string;
  coordinates: number[];
}

export interface GeometryLineStringData {
  type: string;
  coordinates: number[][];
}

export interface GeometryPolygonData {
  type: string;
  coordinates: number[][][];
}

export interface GeometryDataInfo {
  type: string;
  coordinates: any;
}
//END NAVIGATION GEO DATA
export interface CurrentAreaInfo {
  areaId: number;
  currentAreaGroupIdx: number;
}

export interface INavigationResourceItem {
  areaId?: number;
  resourceId?: number;
  qrCodeId?: string;
  qrCategoryCodeId?: string;
  name?: string;
  displayNameForApp?: string;
  isResource?: boolean;
}

export enum NavigationStateEnum {
  Default,
  StartSelection,
  TargetSelection,
  StepByStepNavigation,
  ResourcesDetail,
  ShowRouteMessage,
}

export enum RouteStateEnum {
  Unknown,
  NotFound,
  SameStartTarget,
  RequestRestartNavigation,
  StartNotFound,
  TargetNotFound,
  BarrierFreeRouteNotFound,
  InvalidKiosk,
  InvalidKioskLicence,
}

export enum StepByStepNavigationStateEnum {
  NotStarted,
  StepByStepOn,
  StepByStepOff,
}

export interface INavigationData {
  start: INavigationResourceItem;
  target: INavigationResourceItem;
}

export enum AngleDirectionEnum {
  Straight,
  Left,
  Right,
  LowLeft,
  LowRight,
  SuperLowLeft,
  SuperLowRight,
  SuperLeft,
  SuperRight,
}

export interface GroupedInstructionItem {
  areaId: number;
  instructions: InstructionItem[];
}

export interface InstructionItem {
  angleDirection: AngleDirectionEnum;
  distance: number;
  points: number[];
  areaId: number;
  nextAreaId?: number;
  isToNextArea: boolean;
  categoryIcon?: string;
}

export interface PortalDetail {
  parentPortalName: string;
  childPortalName: string;
  parentLayerId: string;
  childLayerId: string;
  parentAreaId: number;
  childAreaId: number;
  parentGeometry: string;
  childGeometry: string;
}

export interface PointInfo {
  x: number;
  y: number;
  areaId: number;
  nextAreaId: number;
  mapRatio: number;
  parentPortal?: PortalDetail;
  childPortal?: PortalDetail;
}

export enum AngleEnum {
  SuperLeft = 'SuperLeft',
  Left = 'Left',
  LowLeft = 'LowLeft',
  SuperLowLeft = 'SuperLowLeft',
  Straight = 'Straight',
  SuperLowRight = 'SuperLowRight',
  LowRight = 'LowRight',
  Right = 'Right',
  SuperRight = 'SuperRight',
}

export interface AreaMetric {
  areaId: number;
  floorplan: string;
  mapRatio: number;
  name: string;
  breadcrumb: string[];
}

export interface NavDirectionItem {
  index: number;
  skip: boolean;
  skipNext: number;
  second: PointInfo;
  first: PointInfo;
  nextAngle: AngleEnum;
  third: PointInfo;
  mapRatio: number;
  qrCodeCode?: string;
  qrCodeName?: string;
  fiInfo: {
    fi: number;
    angle: AngleEnum;
    angleFi: number;
  };
  startPortalName?: string;
  endPortalName?: string;
  distance: number;
  startAreaId: number;
  endAreaId: number;
  newAreadId?: number;
  message: SafeHtml;
  areaMetric: AreaMetric;
  nextAngleType: AngleDirectionEnum;
  parentPortal: any;
  childPortal: any;
  distanceRenew: number;
  categoryName?: string;
  categoryIcon?: string;
  mapName?: string;
  isPortal: boolean;
  isLast: boolean;
  portalToPreviousAreaId?: number;
  isHideInNavigation: boolean;
  nextPointIsHideInNavigation: boolean;
  previousPointIsHideInNavigation: boolean;
}
