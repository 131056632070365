import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AuthHttpService } from '@outlook-addin/cue-http';
import { BehaviorSubject, finalize, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  isRefreshingMSToken = false;
  isLoggingOut = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null!);
  microsoftTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null!);

  constructor(
    private authService: AuthHttpService,
    private router: Router,
    private zone: NgZone,
  ) {}

  private isRequestToMicrosoft(url: string): boolean {
    return url.startsWith('https://graph.microsoft.com');
  }

  private logoutAndGoToLogin() {
    if (!this.isLoggingOut) {
      this.authService.clearLocalStorage();
      console.log('Cannot refresh with refresh token, probably expired. Logging off.');

      this.isLoggingOut = true;

      const returnUrl = this.router.url;

      this.zone.run(() => {
        this.router.navigate(['/authorize'], {
          queryParams: {
            returnUrl,
          },
        });
      });
    }
  }

  handle401MicrosoftError(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingMSToken) {
      this.isRefreshingMSToken = true;
      this.microsoftTokenSubject.next(null);

      return this.authService.tryRefreshMicrosoftToken().pipe(
        switchMap((x) => {
          if (x) {
            this.microsoftTokenSubject.next(x);
            return next.handle(this.addToken(req, true));
          } else {
            return this.handle401Error(req, next);
            // this.logoutAndGoToLogin();
            // return of(x as any);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.logoutAndGoToLogin();
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingMSToken = false;
        }),
      );
    } else {
      return this.microsoftTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(req, true));
        }),
      );
    }
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.authService.tryRefreshToken().pipe(
        switchMap((x) => {
          if (x) {
            this.authService.accessToken = x;
            this.tokenSubject.next(x);
            return next.handle(this.addToken(req, false));
          } else {
            this.logoutAndGoToLogin();
            return of(x as any);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.logoutAndGoToLogin();
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(req, false));
        }),
      );
    }
  }

  private addToken(req: HttpRequest<any>, isRequestToMicrosoft: boolean): HttpRequest<any> {
    const token = isRequestToMicrosoft
      ? this.authService.microsoftAccessToken
      : this.authService.accessToken;
    const headers = req.headers.set('Authorization', 'Bearer ' + token);
    const edited = req.clone({
      headers,
    });
    return edited;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(req, this.isRequestToMicrosoft(req.url))).pipe(
      map((res) => res),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          switch ((error as HttpErrorResponse).status) {
            case 401:
              return this.isRequestToMicrosoft(req.url)
                ? this.handle401MicrosoftError(req, next)
                : this.handle401Error(req, next);
            default:
              return throwError(error);
          }
        } else {
          return throwError(error);
        }
      }),
    );
  }
}
