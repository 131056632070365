<ng-container *ngIf="dataPack$ | async as pack">
    <ng-container *ngIf="pack.error">
        {{ translation.outlookAddin.errorDescription | transloco }}
        <button (click)="refresh()" class="small">
            <cue-icon [icon]="ICONS.cueRefresh"></cue-icon>
            {{ translation.outlookAddin.refresh | transloco }}
        </button>
    </ng-container>
    <ng-container *ngIf="!pack.error">
        <ng-container *ngIf="pack.data as reservationDetail; else noDataFound">
            <div class="mobile-ui">
                <div class="name">
                    <ng-container *ngIf="reservationDetail.subject; else noSubject">
                        <h2>{{ reservationDetail.subject }}</h2>
                    </ng-container>
                    <ng-template #noSubject>
                        <h2 class="no-subject">
                            {{ translation.outlookAddin.noSubject | transloco }}
                        </h2>
                    </ng-template>

                    <ng-container [ngSwitch]="reservationDetail | reservationStatus">
                        <span
                            class="accepted"
                            *ngSwitchCase="reservationEventStatusEnum.Confirmed"
                            >{{ translation.common.accepted | transloco }}</span
                        >
                        <span
                            class="canceled"
                            *ngSwitchCase="reservationEventStatusEnum.Cancelled"
                            >{{ translation.common.cancelled | transloco }}</span
                        >
                        <span
                            class="tentative"
                            *ngSwitchCase="reservationEventStatusEnum.Tentative"
                            >{{ translation.common.tentative | transloco }}</span
                        >
                        <span
                            class="canceled"
                            *ngSwitchCase="reservationEventStatusEnum.UnconfirmEventCanceled"
                            >{{ translation.common.unconfirmed | transloco }}</span
                        >
                    </ng-container>
                </div>
                <div class="tabs-container">
                    <div class="tabs">
                        <div
                            class="tab-item"
                            [class.active]="activeTabIndex === 0"
                            #tab
                            (click)="setActiveTab(0)"
                        >
                            <span>{{ translation.outlookAddin.locationDetail | transloco }}</span>
                        </div>
                        <div
                            class="tab-item"
                            [class.active]="activeTabIndex === 1"
                            [class.disabled]="reservationDetail?.orders?.length === 0"
                            #tab
                            (click)="setActiveTab(1)"
                        >
                            <span
                                >{{ translation.outlookAddin.orders | transloco }} ({{
                                    reservationDetail?.orders?.length
                                }})</span
                            >
                        </div>
                    </div>
                    <div
                        class="tabs-slider"
                        [ngStyle]="{
                            width: hrStyle.width + 'px',
                            transform: 'translateX(' + hrStyle.left + 'px)'
                        }"
                    ></div>
                </div>

                <div
                    class="slides"
                    #slider
                    [class.disabled]="reservationDetail?.orders?.length === 0"
                >
                    <div class="slide" #slide>
                        <addin-location-detail
                            [resourceList]="reservationDetail.resources"
                        ></addin-location-detail>
                    </div>
                    <div class="slide" #slide>
                        <addin-order-detail [orderList]="reservationDetail.orders">
                        </addin-order-detail>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #noDataFound>
            <div class="not-found">
                <cue-icon [icon]="ICONS.cueClose"></cue-icon>
                {{ translation.outlookAddin.noResourceOnEvent | transloco }}
            </div>
        </ng-template>
    </ng-container>
</ng-container>
