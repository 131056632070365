import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  currentUserLoaded,
  currentUserSettingsLoaded,
  loadCurrentUser,
  loadCurrentUserSettings,
} from '../actions/current-user.actions';
import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { ApiService, getUserInfo, getUserSettings } from '@cue/api';

@Injectable()
export class CurrentUserEffects {
  loadCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrentUser),
      switchMap(() => this.apiService.call(getUserInfo())),
      map((data) =>
        currentUserLoaded({
          user: data,
        }),
      ),
    ),
  );

  loadCurrentUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCurrentUserSettings),
      switchMap(() => this.apiService.call(getUserSettings())),
      map((data) =>
        currentUserSettingsLoaded({
          userSettings: data,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
  ) {}
}
