import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { TouchOneHttpService } from '@outlook-addin/cue-http';
import { ResourcesActions } from '../actions';
import { concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromModule from '../reducers';
import { of } from 'rxjs';

@Injectable()
export class ResourcesEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private touchOneHttpService: TouchOneHttpService,
    private store: Store<fromModule.State>,
  ) {}

  loadFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResourcesActions.loadResourcesFilter),
      switchMap((action) => {
        const data$ = this.touchOneHttpService.loadResourcesFilter();
        return data$.pipe(
          map((result) => {
            return ResourcesActions.resourcesFilterLoaded({
              filter: result,
            });
          }),
        );
      }),
    );
  });

  loadAreas = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResourcesActions.loadAreas),
      switchMap((action) => {
        const data$ = this.touchOneHttpService.loadAreas();
        return data$.pipe(
          map((result) => {
            return ResourcesActions.areasLoaded({
              areas: result,
            });
          }),
        );
      }),
    );
  });

  reloadResourceList = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResourcesActions.resetRequirements,
        ResourcesActions.resourcesFilterLoaded,
        ResourcesActions.updateResourceFilter,
        ResourcesActions.updateStartEndFilter,
        // ResourcesActions.searchTextChanged,
        // ResourcesActions.updateOnlyAvailableFilter
      ),
      concatMap(() => {
        const data$ = of(true);
        return data$.pipe(
          map((result) => {
            return ResourcesActions.loadResourceList();
          }),
        );
      }),
    );
  });

  loadResourceList = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResourcesActions.loadResourceList),
      concatLatestFrom(() => this.store.select(fromModule.requirements)),
      switchMap(([action, { reqs, oldOne, start, end }]) => {
        const payload = {
          start: start,
          end: end,
          requirements: reqs,
        };
        const data$ = this.touchOneHttpService.loadResourceList(payload);
        // return of(ResourcesActions.resourceListLoaded({
        //   resourceList: []
        // }));
        return data$.pipe(
          map((result) => {
            return !result.error
              ? ResourcesActions.resourceListLoadSuccess({
                  resourceList: result.data,
                })
              : ResourcesActions.resourceListLoadFailed({
                  error: result.error,
                });
          }),
        );
      }),
    );
  });

  loadResourceDetail = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResourcesActions.loadResourceDetail),
      switchMap((action) => {
        const data$ = this.touchOneHttpService.loadResourceDetail(action.resourceId);

        return data$.pipe(
          map((result) => {
            return !result.error
              ? ResourcesActions.resourceDetailLoadSuccess({
                  resourceDetail: result.data,
                })
              : ResourcesActions.resourceDetailLoadFailed({
                  error: result.error,
                });
          }),
        );
      }),
    );
  });
}
