<table>
    <ng-container *ngFor="let level of data">
        <tr>
            <td>
                <div class="input-wrapper">
                    <assist-checkbox
                        [class.subselected]="childSelected(level)"
                        [attr.id]="level.key"
                        [checked]="level.selected"
                        (change)="onSelect(level, !level.selected)"
                    ></assist-checkbox>
                </div>
            </td>
            <td (click)="level.open = !level.open">
                <fa-icon
                    [icon]="faCaretRight"
                    *ngIf="!level.open && level.children.length > 0"
                ></fa-icon>
                <fa-icon
                    [icon]="faCaretDown"
                    *ngIf="level.open && level.children.length > 0"
                ></fa-icon>
                <span>{{ level.name }} </span>
            </td>
        </tr>

        <ng-container
            [ngTemplateOutlet]="sublevel"
            [ngTemplateOutletContext]="{ $implicit: level, offset: 1, brightness: 100 }"
        ></ng-container>
        <ng-template #sublevel let-parent let-offset="offset" let-brightness="brightness">
            <ng-container *ngIf="parent.open">
                <ng-container *ngFor="let subLevel of parent.children">
                    <tr class="sublevel">
                        <td>
                            <div class="input-wrapper">
                                <assist-checkbox
                                    [class.subselected]="childSelected(subLevel)"
                                    class="marianova-classa"
                                    type="checkbox"
                                    [attr.id]="subLevel.key"
                                    [checked]="subLevel.selected"
                                    (change)="onSelect(subLevel, !subLevel.selected)"
                                >
                                </assist-checkbox>
                            </div>
                        </td>
                        <td
                            [style.padding-left]="offset * 20 + 'px'"
                            (click)="subLevel.open = !subLevel.open"
                        >
                            <fa-icon
                                [icon]="faCaretRight"
                                *ngIf="!subLevel.open && subLevel.children.length > 0"
                            ></fa-icon>
                            <fa-icon
                                [icon]="faCaretDown"
                                *ngIf="subLevel.open && subLevel.children.length > 0"
                            ></fa-icon>
                            <span
                                [class]="
                                    subLevel.children.length > 0 ? 'parent-label' : 'item-label'
                                "
                                >{{ subLevel.name }}
                            </span>
                        </td>
                    </tr>
                    <ng-container
                        [ngTemplateOutlet]="sublevel"
                        [ngTemplateOutletContext]="{
                            $implicit: subLevel,
                            offset: offset + 1,
                            brightness: brightness + 10
                        }"
                    ></ng-container>
                </ng-container>
            </ng-container>
        </ng-template>
    </ng-container>
</table>
