import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';
import { allResources } from './resources.selectors';
import { AssistResourceBM, ServerType } from '@cue/api';
import { getAttendeeFromLocationAndAttendees, objectSelector } from '../utils';
import { AppState, Reservations, SharedState } from '../models';

export const getReservations = createSelector<AppState, SharedState, Reservations>(
  selectShared,
  (state) => state.reservations as Reservations,
);

export const reservationsSettings = objectSelector(
  getReservations,
  (_reservations: Reservations) => ({
    from: _reservations.from,
    to: _reservations.to,
    state: _reservations.state,
    resourceNames: _reservations.resourceNames,
    attendees: _reservations.attendees,
    nextLink: _reservations.nextLink,
  }),
);

export function getMode(): ServerType {
  return localStorage.getItem('LOGIN_MODE') as ServerType;
}

//export function geImageUrl(
//  resources: AssistResourceBM[],
//  uniqueId: string,
//  displayName: string
//) {
//  return getImageUrl(uniqueId, displayName, resources);
//}

export const reservationsAsScheduledEvents = (email: string) =>
  createSelector(getReservations, allResources, (_reservations, _packedResources) => {
    return {
      ..._reservations,
      data: _reservations.data.sort(
        (eventA, eventB) =>
          new Date(eventA.start.dateTime).getTime() - new Date(eventB.start.dateTime).getTime(),
      ),
    };
  });

export const filteredReservations = (email: string) =>
  createSelector(
    reservationsAsScheduledEvents(email),
    allResources,
    reservationsSettings,
    (s1, _resources, _settings) => {
      const data = s1.data.filter((event) => {
        const attendeeFromLocation = getAttendeeFromLocationAndAttendees(event, _resources.data);
        const attendeesMails: string[] = event.attendees
          .map((a) => a.emailAddress.address)
          .filter((x) => x != null);
        return (
          attendeeFromLocation != null &&
          attendeesMails.some((m) =>
            _resources.data!.map((y) => y.email).some((f) => f.toUpperCase() === m.toUpperCase()),
          )
        );
      });

      return {
        data: data,
        loading: s1.loading || _resources.loading,
        nextLink: _settings.nextLink,
        packedResources: _resources,
      };
    },
  );
