import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AppState, Portal } from '../models';
import { selectShared } from './shared.selectors';

export const portal = createSelector(selectShared, (state) => state.portal);

export const getFiltersOpened = createSelector<AppState, Portal, boolean>(
  portal,
  (f) => f.filtersOpened,
);
