import { AddinConfigService } from '@outlook-addin/shared';
import { environment } from '../../assist/src/environments/environment';
import { enableProdMode } from '@angular/core';
import { forkJoin, from } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { STORAGE_KEYS } from '@outlook-addin/cue-http';
import { JwtHelper } from '../../../libs/outlook-addin/cue-http/src/lib/jwt.helper';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
}

const availableCodes = {
  ca: () => [
    import('@angular/common/locales/ca'),
    import('@progress/kendo-angular-intl/locales/ca/all'),
  ],
  ua: () => [
    import('@angular/common/locales/ru-UA'),
    import('@progress/kendo-angular-intl/locales/ru-UA/all'),
  ],
  fr: () => [
    import('@angular/common/locales/fr'),
    import('@progress/kendo-angular-intl/locales/fr/all'),
  ],
  no: () => [
    import('@angular/common/locales/nb'),
    import('@progress/kendo-angular-intl/locales/nb/all'),
  ],
  nb: () => [
    import('@angular/common/locales/nb'),
    import('@progress/kendo-angular-intl/locales/nb/all'),
  ],
  en: () => [
    import('@angular/common/locales/en'),
    import('@progress/kendo-angular-intl/locales/en/all'),
  ],
  cs: () => [
    import('@angular/common/locales/cs'),
    import('@progress/kendo-angular-intl/locales/cs/all'),
  ],
  es: () => [
    import('@angular/common/locales/es'),
    import('@progress/kendo-angular-intl/locales/es/all'),
  ],
};

const defaultImport = () => [
  import('@angular/common/locales/en'),
  import('@progress/kendo-angular-intl/locales/en/all'),
];
function getLocaleId(): [Promise<any>, Promise<any>] {
  const userLang = navigator.language || (navigator as any).userLanguage;
  const code = userLang.split('-')[0];
  const foundCode = availableCodes[code];
  const returnCode = foundCode ? foundCode() : defaultImport();
  return returnCode;
}

const service = new AddinConfigService();

const angularBootstrap = () =>
  bootstrapApplication(AppComponent, appConfig(service)).catch((err) => console.error(err));

const promises = getLocaleId();
forkJoin(promises.map((x) => from(x))).subscribe((lang) => {
  registerLocaleData(lang[0].default);
  service.bootstrap().then(
    (resolve) => {
      Office.onReady().then((x) => {
        if (Office.context.mailbox) {
          localStorage.removeItem(STORAGE_KEYS.outlookLoginMode);
          const currentUserToken = localStorage.getItem(STORAGE_KEYS.accessToken);

          if (
            localStorage.getItem(STORAGE_KEYS.outlookUsername) ===
            Office.context.mailbox.userProfile.emailAddress
          ) {
            //current user is the same as already logged in user
          } else {
            localStorage.setItem(
              STORAGE_KEYS.outlookUsername,
              Office.context.mailbox.userProfile.emailAddress,
            );
            if (currentUserToken) {
              const jwtHelper = new JwtHelper();
              if (
                Office.context.mailbox.userProfile.emailAddress !=
                jwtHelper.getUsernameFromToken(currentUserToken)
              ) {
                localStorage.removeItem(STORAGE_KEYS.accessToken);
                localStorage.removeItem(STORAGE_KEYS.refreshToken);
                localStorage.removeItem(STORAGE_KEYS.accessTokenMicrosoft);
                localStorage.removeItem(STORAGE_KEYS.refreshTokenMicrosoft);
                localStorage.removeItem(STORAGE_KEYS.code);
              }
            } else {
              localStorage.removeItem(STORAGE_KEYS.accessToken);
              localStorage.removeItem(STORAGE_KEYS.refreshToken);
              localStorage.removeItem(STORAGE_KEYS.accessTokenMicrosoft);
              localStorage.removeItem(STORAGE_KEYS.refreshTokenMicrosoft);
              localStorage.removeItem(STORAGE_KEYS.code);
            }
          }
        }
        angularBootstrap();
      });
    },
    (reject) => {
      // debilni O365 js overriduje alert....
      const div = document.createElement('div');
      div.innerHTML = 'Cannot start application: ' + reject;
      document.body.prepend(div);
    },
  );
});
