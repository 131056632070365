import { Memoize, MemoizeExpiring } from 'typescript-memoize';
import { CalendarAvailability, CalendarSchedulingSettings } from '@cue/calendars';
import {
  isDifferent,
  patchAvailabilityByRestrictions,
  patchAvailabilityByWorkingHours,
} from './event.utils';
import { Filter, WorkingHours } from '../models';
import { add, getHours, getMinutes, set, startOfDay } from 'date-fns';
import { getNextQuarter } from './calculations.functions';

export class ScheduleHelper {
  @MemoizeExpiring(
    60000,
    (
      availabilityView: CalendarAvailability[],
      workingHours: WorkingHours,
      timezone: string,
      filter: Filter,
      schedulingSettings?: CalendarSchedulingSettings,
    ) => {
      return (
        availabilityView.join('') +
        ':' +
        +timezone +
        ':' +
        schedulingSettings?.minimumMeetingDurationInMinutes +
        ':' +
        schedulingSettings?.minimumMeetingDurationInMinutes +
        ':' +
        schedulingSettings?.maxAdvanceDays +
        ':' +
        schedulingSettings?.rejectOutsideWorkingHours +
        filter.date.toISOString() +
        ':' +
        filter.duration?.toISOString() +
        ':' +
        JSON.stringify(workingHours)
      );
    },
  )
  patchAvailabilityViewByWorkingHoursAndLimits(
    availabilityView: CalendarAvailability[],
    wHours: WorkingHours,
    timezoneName: string,
    filter: Filter,
    schedulingSettings?: CalendarSchedulingSettings,
  ) {
    const patchedByWorkingHours = patchAvailabilityByWorkingHours(
      startOfDay(filter.date),
      availabilityView,
      wHours,
      timezoneName,
      schedulingSettings,
    );

    const newNow = getNextQuarter(new Date());
    const durationMoment =
      filter.duration != null ? filter.duration : set(new Date(), { hours: 0, minutes: 15 });
    const hours = getHours(durationMoment);
    const minutes = getMinutes(durationMoment);
    const earliestPossible = set(add(newNow, { hours: -hours, minutes: -minutes }), {
      seconds: 0,
      milliseconds: 0,
    });
    return patchedByWorkingHours.map((availabilityBit, index) => {
      const startTime = add(startOfDay(filter.date), { minutes: index * 15 });

      if (set(startTime, { seconds: 0, milliseconds: 0 }) < earliestPossible)
        return CalendarAvailability.limited;
      return availabilityBit;
    });
  }

  @Memoize(
    (
      resourceTimeZone: string,
      start: Date,
      availabilityView: CalendarAvailability[],
      schedulingSettings?: CalendarSchedulingSettings,
      restrictedTo?: 'you' | 'other',
    ) =>
      resourceTimeZone +
      ':' +
      start.toISOString() +
      ':' +
      availabilityView.join('') +
      ':' +
      restrictedTo +
      ':' +
      schedulingSettings?.minimumMeetingDurationInMinutes +
      ':' +
      schedulingSettings?.minimumMeetingDurationInMinutes +
      ':' +
      schedulingSettings?.maxAdvanceDays +
      ':' +
      schedulingSettings?.rejectOutsideWorkingHours,
  )
  patchAvailabilityByRestrictions(
    resourceTimeZone: string,
    start: Date,
    availabilityView: CalendarAvailability[],
    schedulingSettings?: CalendarSchedulingSettings,
    restrictedTo?: 'you' | 'other',
  ) {
    const now = new Date();
    return patchAvailabilityByRestrictions(
      resourceTimeZone,
      start,
      now,
      availabilityView,
      schedulingSettings,
      restrictedTo,
    );
  }

  @Memoize(
    (availabilityView: CalendarAvailability[], filter: Filter) =>
      availabilityView.join('') + ':' + filter.duration?.toISOString(),
  )
  patchAvailabilityViewByLimits(availabilityView: CalendarAvailability[], filter: Filter) {
    let lastIndex = 0;
    let lastValue = availabilityView[lastIndex];
    let sectors = 0;
    const requiredSectors =
      filter.duration != null
        ? (getHours(filter.duration) * 60 + getMinutes(filter.duration)) / 15
        : 1;

    for (let i = 1; i <= availabilityView.length; i++) {
      if (i == availabilityView.length) {
        sectors++;
      }
      if (isDifferent(availabilityView[i], lastValue)) {
        if (
          sectors < requiredSectors &&
          (availabilityView[i - 1] === CalendarAvailability.free ||
            availabilityView[i - 1] === CalendarAvailability.freeOutsideWorkingHours)
        ) {
          for (let j = lastIndex; j < i; j++) {
            availabilityView[j] = CalendarAvailability.limited;
          }
        }
        lastIndex = i;
        lastValue = availabilityView[lastIndex];
        sectors = 1;
      } else {
        sectors++;
      }
    }

    return availabilityView;
  }
}

export const scheduleHelper = new ScheduleHelper();
