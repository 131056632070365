import { Injectable } from '@angular/core';
import { CommonHttpService } from './common-http.service';
import { Observable } from 'rxjs';
import { IReservationDetail } from '../models/reservations.models';
import { Pack } from '@assist/shared/data';
import { AddinConfigService } from '@outlook-addin/shared';
import { IAreaDetail, IPropertyFilter, IResourceListItem } from '../models/resources.models';
import { UserInfo } from '../models';

//TODO: Presunout nekde do sdileneho mista aby nebyla circlular dep
// import { NavigationDetailInfo } from "@assist/navigation/services";

@Injectable({
  providedIn: 'root',
})
export class TouchOneHttpService {
  constructor(
    protected http: CommonHttpService,
    private configService: AddinConfigService,
  ) {}

  getMeInfo(): Observable<Pack<UserInfo>> {
    const url = this.configService.value.apiURL + `/api/account/me`;

    return this.http.get<any, any>(
      url,
      (x: any) => {
        return { data: x, success: true };
      },
      (error) => {
        return {
          success: false,
          error: error.message,
        };
      },
    );
  }

  loadReservationDetail(event: any): Observable<Pack<IReservationDetail>> {
    const url = this.configService.value.apiURL + `/api/addin/event-info`;

    return this.http.post<any, any>(
      url,
      event.event,
      (x: any) => {
        return { data: x, success: true };
      },
      (error) => {
        return {
          success: error.status == 404,
          error: error.status == 404 ? null : error.message,
          data: error.status == 404 ? null : error,
        };
      },
    );
  }

  getICalUid(itemId: string): Observable<any> {
    const url = `https://graph.microsoft.com/v1.0/me/events/${itemId}`;

    return this.http.get<any, any>(url, (x: any) => {
      return x;
    });
  }
  loadResourcesFilter(): Observable<IPropertyFilter[]> {
    const url = this.configService.value.apiURL + `/api/addin/resource-filter`;

    return this.http.get<any, any>(url, (x: any) => {
      return x;
    });
  }

  loadAreas(): Observable<IAreaDetail[]> {
    const url = this.configService.value.apiURL + `/api/addin/areas`;

    return this.http.get<IAreaDetail[], any>(url, (x) => {
      return x;
    });
  }

  loadResourceList(payload: any): Observable<Pack<IResourceListItem[]>> {
    const url = this.configService.value.apiURL + `/api/addin/resources-list`;

    return this.http.post<any, any>(
      url,
      {
        start: payload.start,
        end: payload.end,
        requirements: payload.requirements.map((req) => {
          return {
            id: req.id,
            resourceTypeId: req.resourceTypeId,
            capacityMin: req.capacity && req.capacity[0] ? req.capacity[0] : null,
            capacityMax: req.capacity && req.capacity[1] ? req.capacity[1] : null,
            areaIds: req.areaIds,
            resourceAdditionalFilterList: req.filters.map((filter) => {
              return {
                id: filter.id,
                dataTypeId: filter.dataTypeId,
                valueNumberMin: filter.valueNumberMin,
                valueNumberMax: filter.valueNumberMax,
                valueDecimalMin: filter.valueDecimalMin,
                valueDecimalMax: filter.valueDecimalMax,
                valueBoolean: filter.valueBoolean,
                valueString: filter.valueString,
                valueChoiceId: filter.valueChoiceId?.map((x) => {
                  return {
                    id: x.id,
                  };
                }),
              };
            }),
          };
        }),
      },
      (x) => {
        // TODO filtrovat selectorem
        return {
          success: true,
          data: x.map((item) => {
            item.resourceDisplayName = item.resourceDisplayNameForApp || item.resourceDisplayName;
            return item;
          }),
        };
      },
      (error) => {
        return {
          success: false,
          data: [],
          error: error.message,
        };
      },
    );
  }

  loadResourceDetail(resourceId?: number): Observable<Pack<any>> {
    const url = this.configService.value.apiURL + '/api/navigation/detail/resource/' + resourceId;
    return this.http.get<any, any>(
      url,
      (x: any) => {
        return { data: x, success: true };
      },
      (error) => ({ error: error.message, success: false }),
    );
  }
}
