import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Optional,
  Host,
  SkipSelf,
  Input,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { ClearableDirective } from '@cue/utilities';
import { TextBoxModule, TextBoxComponent } from '@progress/kendo-angular-inputs';
import {
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { FloatingLabelComponent } from '@progress/kendo-angular-label';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'assist-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TextBoxModule,
    FontAwesomeModule,
    ClearableDirective,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true,
    },
  ],
})
export class TextboxComponent implements ControlValueAccessor {
  onChange: any = (val) => {};
  onTouch: any = (val) => {};

  @ViewChild('textBox') textBox: TextBoxComponent;
  @Input() placeholder: string;
  @Input() iconBefore: IconProp;
  _control = new FormControl<string>('', { nonNullable: true });
  @Input() showClear: boolean;
  private _ignoreEmit: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() public floatingLabel: FloatingLabelComponent,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {
    this._control.valueChanges.subscribe((val) => {
      if (this._ignoreEmit) {
        this._ignoreEmit = false;
      } else {
        this.onChange(val);
      }
    });
  }

  focus() {
    this.textBox.focus();
    this.cdr.markForCheck();
    this.onTouch();
    if (this.floatingLabel) {
      this.floatingLabel.focused = true;
    }
  }

  blur() {
    this.cdr.markForCheck();
    if (this.floatingLabel) {
      this.floatingLabel.focused = false;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this._ignoreEmit = true;
    this._control.setValue(obj);
    this.cdr.markForCheck();
  }
}
