import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  Optional,
  Host,
  SkipSelf,
  Injector,
  ContentChild,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  DropDownFilterSettings,
  MultiSelectModule,
  PopupSettings,
} from '@progress/kendo-angular-dropdowns';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FloatingLabelComponent } from '@progress/kendo-angular-label';
import { ItemTemplateDirective } from '../../directives/item-template.directive';
import { TagTemplateDirective } from '../../directives/tag-template.directive';
import { NoDataTemplateDirective } from '../../directives/no-data-template.directive';

@Component({
  selector: 'assist-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  standalone: true,
  imports: [CommonModule, MultiSelectModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true,
    },
  ],
})
export class MultiselectComponent implements OnInit, ControlValueAccessor {
  @ContentChild(ItemTemplateDirective, { static: true, read: TemplateRef }) itemTemplate;
  @ContentChild(TagTemplateDirective, { static: false, read: TemplateRef }) tagTemplate;
  @ContentChild(NoDataTemplateDirective, { static: true, read: TemplateRef }) noDataTemplate;

  @Output() open = new EventEmitter<any>();
  @Input() filterable: boolean;
  @Input() popupSettings: PopupSettings;
  @Input() data: any[];
  @Input() placeholder: string;
  @Input() dropdownFilter: DropDownFilterSettings;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() allowCustom: boolean;

  @Input() value: any;
  @Input() valuePrimitive: boolean;
  @Output() valueChange = new EventEmitter<any[]>();
  @Output() filterChange = new EventEmitter<string>();

  _control: AbstractControl;
  @Input() valueNormalizer: (any) => any = (x) => x;

  constructor(
    @Optional() public floatingLabel: FloatingLabelComponent,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    private injector: Injector,
  ) {}

  focus() {
    if (this.floatingLabel) {
      this.floatingLabel.focused = true;
    }
  }

  blur() {
    if (this.floatingLabel) {
      this.floatingLabel.focused = false;
    }
  }

  ngOnInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null);

    if (ngControl == null) {
      this._control = new FormControl<any[]>(this.value);
      return;
    }
    if (ngControl.control) {
      this._control = ngControl.control as FormControl<any[]>;
    } else {
      if (this.controlContainer) {
        this._control = (this.controlContainer.control as any).get(ngControl.name);
      } else {
        throw Error('No control found!');
      }
    }
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {}
}
