import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[textareaAutoresize]',
  standalone: true,
  host: {
    class: 'resizeable-textarea',
  },
})
export class TextareaAutoresizeDirective implements AfterViewInit, OnDestroy {
  _textarea: HTMLTextAreaElement;
  _sub: Subscription;
  constructor(elementRef: ElementRef) {
    if (elementRef.nativeElement.tagName.toUpperCase() !== 'TEXTAREA') {
      throw new Error(
        '[TextAreaAutoresizeDirective] Directive has to be attached on TextArea HTML ELEMENT!',
      );
    }

    this._textarea = elementRef.nativeElement;
    this._sub = fromEvent(this._textarea, 'input').subscribe(() => {
      this.resize();
    });
  }

  resize() {
    this._textarea.style.height = '0';
    this._textarea.style.height = this._textarea.scrollHeight + 'px';
  }

  ngAfterViewInit(): void {
    this.resize();
  }

  ngOnDestroy(): void {
    this._sub?.unsubscribe();
  }
}
