import { Injectable } from '@angular/core';
import { AddinApiConfig, AddinConfigFile, AddinConfiguration } from '../models';

@Injectable()
export class AddinConfigService {
  private _config: AddinConfiguration;

  get value(): AddinConfiguration {
    if (!this._config)
      throw Error(
        'Value of config service is not initialized, are you sure you have called it in initializer / pre bootstrap?',
      );
    return this._config;
  }

  bootstrap(): Promise<AddinConfiguration> {
    return new Promise((resolve, reject) => {
      const configRequest = new XMLHttpRequest();
      configRequest.addEventListener('error', (e) => {
        reject('Cannot load config.json file.');
      });
      configRequest.addEventListener('load', (_) => {
        if (configRequest.status === 200) {
          try {
            const addinConfigFile = JSON.parse(configRequest.responseText) as AddinConfigFile;
            const apiConfigRequest = new XMLHttpRequest();
            apiConfigRequest.addEventListener('error', (e) => {
              reject('Cannot connect to API server, check if it is available');
            });
            apiConfigRequest.addEventListener('load', () => {
              if (apiConfigRequest.status !== 200 && apiConfigRequest.status !== 204) {
                reject(apiConfigRequest.statusText);
              }
              let addinApiConfig: AddinApiConfig = null;

              if (apiConfigRequest.status === 200) {
                try {
                  addinApiConfig = JSON.parse(apiConfigRequest.responseText) as AddinApiConfig;

                  addinApiConfig = {
                    ...addinApiConfig,
                    configuration: {
                      ...addinApiConfig.configuration,
                    },
                  };
                } catch (e) {
                  reject(e);
                }
              }
              if (addinApiConfig == null) {
                console.error(
                  'Spatna konfigurace na serveru, /api/addin/configuration nevratil zadna data!',
                );
                reject('Addin is not configured. Edit the settings in CUE Admin.');
              }

              this._config = {
                ...addinConfigFile,
                ...addinApiConfig,
              } as AddinConfiguration;
              resolve(null);
            });
            const configurationUrl = addinConfigFile.apiURL + '/api/addin/configuration';
            apiConfigRequest.open('GET', configurationUrl);
            apiConfigRequest.send();
          } catch (e) {
            reject(e);
          }
        } else {
          reject(configRequest.statusText);
        }
      });
      configRequest.open('GET', '/config.json');
      configRequest.send();
    });
  }
}
