import { Component, OnDestroy } from '@angular/core';
import { translation } from '@assist/shared/translations';
import { Store } from '@ngrx/store';
import * as fromModule from '../../ngrx/reducers';
import { AuthHttpService, STORAGE_KEYS } from '@outlook-addin/cue-http';
import { Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'addin-main-menu-container',
  templateUrl: './main-menu-container.component.html',
  styleUrls: ['./main-menu-container.component.scss'],
  standalone: true,
  imports: [MenuComponent, RouterModule],
})
export class MainMenuContainerComponent implements OnDestroy {
  subscription: Subscription;
  outlookUserDifferentFromAddin$ = this.store.select(
    fromModule.selectOutlookUserDifferentFromAddin,
  );
  protected readonly translation = translation;
  constructor(
    private store: Store<fromModule.State>,
    private authService: AuthHttpService,
    private router: Router,
  ) {
    this.subscription = this.outlookUserDifferentFromAddin$.subscribe((x) => {
      if (x) {
        this.authService.signOut();
        localStorage.removeItem(STORAGE_KEYS.code);
        this.router.navigate(['login']);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
