<cue-progressive-image
    *ngIf="src !== null"
    [imgWidth]="imgWidth"
    [imgHeight]="imgHeight"
    [imgMaxHeight]="imgMaxHeight"
    [ngStyle]="{ 'max-width': imgMaxWidth }"
>
    <img
        cueProgressiveImage
        [src]="src | imageDimensions: { width: width, height: loadHeight }"
        [class]="imageClass"
    />
    <div class="placeholder" cueProgressiveImagePlaceholder>
        <cue-loading></cue-loading>
    </div>
</cue-progressive-image>
