import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap, switchMapTo } from 'rxjs/operators';

import * as UserActions from '../actions/user.actions';
import { forkJoin } from 'rxjs';
import { CalendarsServiceFactoryService } from '@cue/calendars';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../services';

@Injectable()
export class UserEffects {
  calendarImplementation$ = this.calendarsServiceFactory.getImplementation(
    this.authService.getMode(),
  );
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUser),
      switchMapTo(
        forkJoin([
          this.calendarImplementation$.pipe(
            switchMap((implementation) => implementation.getMeInfo()),
          ),
          this.calendarImplementation$.pipe(
            switchMap((implementation) => implementation.getImageUrl('me')),
            map((url) => this.sanitizer.bypassSecurityTrustUrl(url)),
          ),
        ]),
      ),
      map(([user, safeurl]) => UserActions.userLoaded({ user: { ...user, base64Image: safeurl } })),
    ),
  );

  constructor(
    private sanitizer: DomSanitizer,
    private actions$: Actions,
    private authService: AuthService,
    private calendarsServiceFactory: CalendarsServiceFactoryService,
  ) {}
}
