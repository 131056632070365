import { createSelector } from '@ngrx/store';
import { selectShared } from './shared.selectors';
import { AssistResourceBM } from '@cue/api';
import { Pack } from '../models/index';
import { currentUserSettings } from './current-user.selectors';

export const allResources = createSelector(selectShared, (s) => s.resources);

export const allFilteredResources = createSelector(
  selectShared,
  currentUserSettings,
  (s, settings) => {
    const ids = s.areas.data.map((x) => x.id);
    const filteredIds = settings.data?.location.filteredAreaIds;
    const allowedIds = ids.filter((id) => !filteredIds?.includes(id));
    return {
      data: s.resources.data?.filter((resource) =>
        allowedIds.includes(resource.mapInfos[0].areaId),
      ),
      loading: s.resources.loading,
      error: s.resources.error,
    };
  },
);

export const resourceById = (resourceId) =>
  createSelector(allResources, (s) =>
    s.loading
      ? ({
          loading: true,
          data: null,
        } as Pack<AssistResourceBM>)
      : {
          loading: false,
          data: s.data.find((x) => x.id === resourceId),
        },
  );
