<div class="safe-padding filter-button" *ngIf="!showFilter">
    <button (click)="showFilter = !showFilter" class="small">
        <cue-icon [icon]="cueFilter"></cue-icon>
        {{ translation.outlookAddin.openFilters | transloco }}
    </button>

    <div class="remove-filters" *ngIf="reqCount$ | async as requirementsCount">
        {{ requirementsCount }}
        {{ translation.outlookAddin.activeFilterCount | transloco }}
        <cue-icon [icon]="cueClose" (click)="resetAllFilters()"></cue-icon>
    </div>
</div>
<addin-resource-filter
    *ngIf="showFilter"
    [filterCount]="reqCount$ | async"
    [hierarchyAreas]="hierarchyAreas$ | async"
    [currentFilter]="currentResourceFilter$ | async"
    [areas]="areas$ | async"
    [resourceFilters]="resourcesFilter$ | async"
    (refreshFilter)="showFilter = false"
>
</addin-resource-filter>
<addin-resource-list
    (refresh)="onRefresh()"
    [hidden]="showFilter"
    [resourceFilter]="currentResourceFilter$ | async"
    [resourceList]="resourceList$ | async"
    [groupedResourceList]="groupedResourceList$ | async"
>
</addin-resource-list>
