import {
  ContentChildren,
  Directive,
  ElementRef,
  HostListener,
  QueryList,
  Input,
} from '@angular/core';

@Directive({
  selector: 'cue-button[moveNextMaxLength], input[moveNextMaxLength], textarea[moveNextMaxLength]',
  standalone: true,
})
export class MoveNextByMaxLengthDirective {
  next: HTMLElement;
  @Input() keepLast = false;
  @HostListener('input', ['$event']) onKeyDown(keyboardEvent: KeyboardEvent) {
    const target = keyboardEvent.target as HTMLInputElement | HTMLTextAreaElement | null;

    if (!target || target.maxLength !== target.value.length) return;

    keyboardEvent.preventDefault();

    if (this.next) {
      this.next.focus();
    }

    if (this.next == null && !this.keepLast) {
      target.blur();
    }
  }

  constructor(public elRef: ElementRef) {}
}

@Directive({
  selector: '[moveNextMaxLengthContainer]',
  standalone: true,
})
export class MoveNextByMaxLengthContainerDirective {
  @ContentChildren(MoveNextByMaxLengthDirective, { descendants: true })
  childrenDirectives: QueryList<MoveNextByMaxLengthDirective>;

  sortThemAll() {
    var childrens = this.childrenDirectives.toArray();
    for (let i = 0; i < childrens.length - 1; i++) {
      childrens[i].next = childrens[i + 1]?.elRef.nativeElement;
    }
  }

  ngAfterViewInit() {
    this.sortThemAll();
    this.childrenDirectives.changes.subscribe((_) => {
      this.sortThemAll();
    });
  }
}
