import { createReducer, on } from '@ngrx/store';
import { ResourcesActions } from '../actions';
import {
  IAreaDetail,
  IPropertyFilter,
  IResourceFilterUpdateCrate,
  IResourceListItem,
  Requirement,
} from '@outlook-addin/cue-http';
import { Pack } from '@assist/shared/data';
import { NavigationDetailInfo } from '@assist/navigation/services';

export type State = {
  areas: IAreaDetail[];
  resourcesFilter: IPropertyFilter[];
  resourceList: Pack<IResourceListItem[]>;
  resourceDetail: Pack<NavigationDetailInfo>;
  currentResourceListFilter: IResourceFilterUpdateCrate;
  requirements: Requirement[];
  virtualRequirements: {
    filter: IPropertyFilter;
    id: string;
  }[];
};

export const initialState: State = {
  areas: [],
  resourcesFilter: null,
  requirements: [],
  virtualRequirements: [],
  resourceList: {
    data: [],
    loading: false,
  },
  resourceDetail: {
    data: null,
    loading: false,
  },
  currentResourceListFilter: {
    start: null,
    end: null,
    additionalFilterList: [],
    resourceName: null,
    showOnlyAvailable: localStorage.getItem('showOnlyAvailableFilter')
      ? localStorage.getItem('showOnlyAvailableFilter') === 'true'
      : null,
  },
};

export const reducer = createReducer(
  initialState,
  on(ResourcesActions.loadAreas, (state) => ({
    ...state,
    areas: [],
  })),
  on(ResourcesActions.areasLoaded, (state, { areas }) => ({
    ...state,
    areas: areas,
  })),
  on(ResourcesActions.loadResourcesFilter, (state) => ({
    ...state,
    resourcesFilter: [],
  })),
  on(ResourcesActions.resourcesFilterLoaded, (state, action) => ({
    ...state,
    resourcesFilter: action.filter,
    virtualRequirements:
      state.requirements.length === 0
        ? action.filter
            .filter((x) => x.resourceCount > 0)
            .map((f) => ({
              filter: f,
              id: crypto.randomUUID(),
            }))
        : [],
  })),
  on(ResourcesActions.loadResourceList, (state) => ({
    ...state,
    resourceList: {
      data: state.resourceList?.data,
      loading: true,
    },
  })),
  on(ResourcesActions.resourceListLoadSuccess, (state, action) => ({
    ...state,
    resourceList: {
      data: action.resourceList,
      loading: false,
    },
    isResourceListLoading: false,
  })),
  on(ResourcesActions.resourceListLoadFailed, (state, action) => ({
    ...state,
    resourceList: {
      data: [],
      loading: false,
      error: action.error,
    },
    isResourceListLoading: false,
  })),
  on(ResourcesActions.loadResourceDetail, (state) => ({
    ...state,
    resourceDetail: {
      data: null,
      loading: true,
    },
  })),
  on(ResourcesActions.resourceDetailLoadSuccess, (state, action) => ({
    ...state,
    resourceDetail: {
      data: action.resourceDetail,
      loading: false,
      error: null,
    },
  })),
  on(ResourcesActions.resourceDetailLoadFailed, (state, action) => ({
    ...state,
    resourceDetail: {
      data: null,
      loading: false,
      error: action.error,
    },
  })),
  on(ResourcesActions.updateStartEndFilter, (state, action) => ({
    ...state,
    currentResourceListFilter: {
      ...state.currentResourceListFilter,
      start: action.start,
      end: action.end,
    },
  })),
  on(ResourcesActions.updateOnlyAvailableFilter, (state, action) => ({
    ...state,
    currentResourceListFilter: {
      ...state.currentResourceListFilter,
      showOnlyAvailable: action.onlyAvailable,
    },
  })),
  on(ResourcesActions.searchTextChanged, (state, action) => ({
    ...state,
    currentResourceListFilter: {
      ...state.currentResourceListFilter,
      resourceName: action.searchText,
    },
  })),

  on(ResourcesActions.resetRequirements, (state) => ({
    ...state,
    requirements: [],
    virtualRequirements: state.resourcesFilter
      .filter((x) => x.resourceCount > 0)
      .map((f) => ({
        filter: f,
        id: crypto.randomUUID(),
      })),
    currentResourceListFilter: {
      ...state.currentResourceListFilter,
      additionalFilterList: [],
    },
  })),
  on(ResourcesActions.updateResourceFilter, (state, action) => ({
    ...state,
    requirements: action.requirements,
    virtualRequirements:
      action.requirements.length === 0
        ? state.resourcesFilter
            .filter((x) => x.resourceCount > 0)
            .map((f) => ({
              filter: f,
              id: crypto.randomUUID(),
            }))
        : [],
    currentResourceListFilter: action.filterUpdate
      ? {
          showOnlyAvailable:
            action.filterUpdate?.showOnlyAvailable != null
              ? action.filterUpdate.showOnlyAvailable
              : state.currentResourceListFilter?.showOnlyAvailable,
          start:
            action.filterUpdate?.start != null
              ? action.filterUpdate.start
              : state.currentResourceListFilter?.start,
          end:
            action.filterUpdate?.end != null
              ? action.filterUpdate.end
              : state.currentResourceListFilter?.end,
          resourceName:
            action.filterUpdate?.resourceName != null
              ? action.filterUpdate.resourceName
              : state.currentResourceListFilter?.resourceName,
          additionalFilterList:
            action.filterUpdate?.additionalFilterList != null
              ? action.filterUpdate.additionalFilterList
              : state.currentResourceListFilter?.additionalFilterList,
        }
      : null,
  })),
);
