<div class="safe-padding" style="text-align: center">
    <ng-container *ngIf="(outlookUserDifferentFromAddin$ | async) === true; else userMatch">
        <h3>{{ translation.outlookAddin.differentOutlookAndAddinUser | transloco }}</h3>
    </ng-container>
    <ng-template #userMatch>
        <h2 *ngIf="userAuthorized">{{ translation.outlookAddin.userIsLoggedIn | transloco }}</h2>
        <h2 *ngIf="!userAuthorized">{{ translation.outlookAddin.loginUserInfo | transloco }}</h2>
    </ng-template>

    <button style="margin-top: 1em" *ngIf="!userAuthorized" (click)="login()">
        <cue-icon [icon]="cueLogin"></cue-icon>
        {{ translation.outlookAddin.login | transloco }}
    </button>
</div>
