import { Component, Input } from '@angular/core';
import { IEventInfoResourceResponseBm } from '@outlook-addin/cue-http';
import { faCheck, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

import { AssistPropertyBM } from '@cue/api';
import { translation } from '@assist/shared/translations';
import { cueExternalLink, IconComponent } from '@cue/ui/icons';
import {
  AssignToPipe,
  AssignToTranslateKeyToPipe,
  ResourceExternalUrlPipe,
} from '../../shared/pipes';
import { TranslocoModule } from '@ngneat/transloco';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ResourceMapComponent } from '../resource-map/resource-map.component';
import { ImageComponent } from '@cue/images';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'addin-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AssignToPipe,
    IconComponent,
    AssignToTranslateKeyToPipe,
    TranslocoModule,
    ResourceExternalUrlPipe,
    FontAwesomeModule,
    ResourceMapComponent,
    ImageComponent,
  ],
})
export class ResourceDetailComponent {
  ICONS = {
    cueExternalLink,
    faChevronRight,
    faTimes,
    faCheck,
  };

  currentImage = undefined;
  translation = translation;
  @Input() resource: IEventInfoResourceResponseBm;
  @Input() newMapFile = '';

  getSimpleFiltersByResourceTypeId(detail: any) {
    return this.getFiltersFromNavigationDetail(detail).filter((x) => x.dataTypeId !== 5);
  }

  private getFiltersFromNavigationDetail(
    detail: any,
  ): { name: string; value: any; dataTypeId: number }[] {
    return detail.properties
      .map((property) => ({
        name: property.name,
        dataTypeId: property.dataTypeId,
        value: this.getValueFromProperty(property),
      }))
      .filter((x) => x.value);
  }

  private getValueFromProperty(
    property: AssistPropertyBM & { dataTypeId: number; valueChoices: any[] },
  ) {
    switch (property.dataTypeId) {
      case 1: {
        return property.valueBoolean;
      }
      case 2: {
        return property.valueNumber;
      }
      case 3: {
        return property.valueDecimal;
      }
      case 4: {
        return property.valueText;
      }
      case 5: {
        return property.valueChoices;
      }
      default:
        return null;
    }
  }

  navigateToResourceDetailInAssist(url: string) {
    window.open(url, '_blank');
  }
}
