import { Observable, of } from 'rxjs';

import { concatMap, withLatestFrom } from 'rxjs/operators';

export function betterLatestFrom<TSource, TSecond>(
  second: (input: TSource) => Observable<TSecond>,
) {
  return function latestFromIsSuxx(source: Observable<TSource>) {
    return Observable.create((subscriber: any) => {
      const subscription = source
        .pipe(concatMap((action) => of(action).pipe(withLatestFrom(second(action)))))
        .subscribe(
          (value) => {
            try {
              subscriber.next(value);
            } catch (err) {
              subscriber.error(err);
            }
          },
          (err) => subscriber.error(err),
          () => subscriber.complete(),
        );
      return subscription;
    }) as Observable<[TSource, TSecond]>;
  };
}
