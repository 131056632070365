<label style="cursor: pointer" [class]="labelClass">
    <ng-container *ngIf="_control">
        <input
            (change)="handleChange($event)"
            [checked]="checked"
            [formControl]="$any(_control)"
            [indeterminate]="allowTripple === true && _control.value === null"
            kendoCheckBox
            type="checkbox"
        />
        <fa-icon
            (click)="_control?.setValue(null); $event.preventDefault(); $event.stopPropagation()"
            *ngIf="allowTripple === true && _control.value != null"
            [icon]="faTimes"
        ></fa-icon>
    </ng-container>

    <input
        (change)="handleChange($event)"
        *ngIf="!_control"
        [checked]="checked"
        kendoCheckBox
        type="checkbox"
    />
    <span>
        {{ title }}
    </span>
</label>
