import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentMapItemService {
  currentItemResourceId: WritableSignal<number> = signal(null);
  currentItemQrCodeId: WritableSignal<string> = signal(null);
  // activeItemResourceId = computed(()=> this.currentItemData().resourceId);
  // activeItemQrCodeId = computed(()=> this.currentItemData().qrCodeId);
  constructor() {}

  updateCurrentItemId(resourceId: number, qrCodeId: string) {
    this.currentItemResourceId.set(resourceId);
    this.currentItemQrCodeId.set(qrCodeId);
  }
}
