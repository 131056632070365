import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  OutlookReservationMode,
  ReservationEventStatusEnum,
  TouchOneHttpService,
} from '@outlook-addin/cue-http';
import * as fromModule from '../../ngrx/reducers';
import { fromEvent, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { IReservationDetail } from '@outlook-addin/cue-http';
import { translation } from '@assist/shared/translations';
import { cueClose, cueRefresh, IconComponent } from '@cue/ui/icons';
import { Pack } from '@assist/shared/data';
import { refreshReservationDetail } from '../../ngrx/actions/reservation.actions';
import { ReservationActions } from '../../ngrx/actions';
import { AsyncPipe, CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ReservationStatusPipe } from '../../shared/pipes';
import { LocationDetailComponent } from '../location-detail/location-detail.component';
import { OrderDetailComponent } from '../order-detail/order-detail.component';

@Component({
  selector: 'addin-reservation-container',
  templateUrl: './reservation-container.component.html',
  styleUrls: ['./reservation-container.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ReservationStatusPipe,
    LocationDetailComponent,
    OrderDetailComponent,
    IconComponent,
  ],
})
export class ReservationContainerComponent implements OnDestroy, AfterViewInit {
  ICONS = {
    cueClose,
    cueRefresh,
  };
  protected readonly translation = translation;

  reservationEventStatusEnum = ReservationEventStatusEnum;
  resetListener$ = new Subject();
  @ViewChildren('tab') tabs!: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('slider') sliders!: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('slide') slides!: QueryList<ElementRef<HTMLDivElement>>;
  activeTabIndex = 0;
  fromTab = false;
  hrStyle = {
    left: 0,
    width: 0,
  };

  subscriptionList: Subscription[] = [];
  private _reservationId: string;
  dataPack$: Observable<Pack<IReservationDetail>>;

  constructor(
    private store: Store<fromModule.State>,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    this.dataPack$ = this.store.select(fromModule.selectReservationDetail);

    Office.onReady().then((x) => {
      if (Office.context.mailbox.item.itemType !== 'appointment') {
        this.zone.run(() => {
          this.store.dispatch(
            ReservationActions.outlookReservationModeChange({
              reservationMode: OutlookReservationMode.NotReservationDetail,
            }),
          );
        });
      } else {
        if (Office.context.mailbox.item.itemId) {
          this.zone.run(() => {
            this.store.dispatch(
              ReservationActions.outlookReservationModeChange({
                reservationMode: OutlookReservationMode.View,
              }),
            );
          });
        } else {
          this.zone.run(() => {
            this.store.dispatch(
              ReservationActions.outlookReservationModeChange({
                reservationMode: OutlookReservationMode.Edit,
              }),
            );
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy');
    this.subscriptionList.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  //TABS
  setActiveTab(index: number) {
    this.fromTab = true;
    this.activeTabIndex = index;
    const slider = this.sliders.toArray()[0];
    slider.nativeElement.scrollTo(
      (slider.nativeElement.children[index] as HTMLElement).offsetLeft,
      0,
    );
  }

  boundSlider() {
    this.resetListener$.next(null);
    const slider = this.sliders.toArray()[0];
    if (!slider) {
      return;
    }
    let ticking = false;
    const tabs = this.tabs.toArray();
    const found = tabs[0];

    if (found && found.nativeElement.getClientRects().length > 0) {
      const relativeLeftToParent = found.nativeElement.offsetLeft;
      const width = found.nativeElement.getClientRects()[0].width + 12;
      this.hrStyle.left = relativeLeftToParent - 6;
      this.hrStyle.width = width;
      this.cdr.detectChanges();
    }
    let lastKnownScrollPosition = 0;
    const recalculate = (scrollPos: number) => {
      let totalWidth = 0;
      const nativeElements = slider.nativeElement.children;

      for (let i = 0; i < nativeElements.length; i++) {
        if (totalWidth + nativeElements[i].clientWidth / 2 > scrollPos) {
          if (i !== this.activeTabIndex) {
            this.zone.run(() => {
              this.activeTabIndex = i;
              const tabs = this.tabs.toArray();
              const found = tabs[i];

              if (found) {
                const relativeLeftToParent = found.nativeElement.offsetLeft;
                const width = found.nativeElement.getClientRects()[0].width + 12;
                this.hrStyle.left = relativeLeftToParent - 6;
                this.hrStyle.width = width;
                this.cdr.detectChanges();
              }
              this.cdr.markForCheck();
            });
          }
          break;
        }
        totalWidth += nativeElements[i].clientWidth;
      }
    };

    this.zone.runOutsideAngular(() => {
      fromEvent(slider.nativeElement, 'scroll')
        .pipe(takeUntil(this.resetListener$))
        .subscribe((e) => {
          lastKnownScrollPosition = slider.nativeElement.scrollLeft;

          if (!ticking) {
            window.requestAnimationFrame(() => {
              recalculate(lastKnownScrollPosition);
              ticking = false;
            });

            ticking = true;
          }
        });
    });
  }

  ngAfterViewInit() {
    this.sliders.changes.subscribe(() => this.boundSlider());
    this.boundSlider();
  }

  //END TABS

  refresh() {
    this.store.dispatch(refreshReservationDetail());
  }
}
