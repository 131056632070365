<header class="navigation">
    <div class="buttons">
        <button
            *ngIf="(currentOutlookReservationMode$ | async) === outlookReservationMode.Edit"
            class="small"
            (click)="navigateToReservationList()"
        >
            {{ translation.outlookAddin.changeLocation | transloco }}
        </button>
    </div>
    <div (click)="mobileHamburger = !mobileHamburger" class="hamburger">
        <cue-icon *ngIf="mobileHamburger" [icon]="ICONS.cueClose"></cue-icon>
        <cue-icon *ngIf="!mobileHamburger" [icon]="ICONS.cueUser"></cue-icon>
    </div>
</header>

<div class="menu-nav" [class.opened]="mobileHamburger">
    <div class="menu-wrapper">
        <div class="menu-item" *ngIf="!isUserLoggedIn()" (click)="loginWithMsDialog()">
            {{ translation.outlookAddin.login | transloco }}
        </div>
        <ng-container *ngIf="isUserLoggedIn()">
            <ng-container *ngIf="userInfo$ | async as userInfo">
                <div class="user-info" *ngIf="!userInfo.loading && userInfo.data">
                    {{ userInfo.data.name }} <span class="email">({{ userInfo.data.email }})</span>
                </div>
                <!--                <div class="menu-item" (click)="logout()">-->
                <!--                    {{ translation.outlookAddin.logout | transloco }}-->
                <!--                </div>-->
                <hr />
            </ng-container>
        </ng-container>

        <div class="data-item language-label">
            <div class="language-wrapper">
                <assist-dropdownlist
                    [data]="languageItems"
                    [formControl]="currentLanguageControl"
                    [popupSettings]="{
                        popupClass: 'language-popup'
                    }"
                    textField="text"
                    valueField="text"
                >
                    <ng-template let-dataItem valueTemplate>
                        <img
                            [src]="'assets/images/flags/' + dataItem?.flag"
                            alt="language-flag"
                            class="flag"
                            height="30"
                            width="30"
                        />
                    </ng-template>
                    <ng-template itemTemplate let-dataItem>
                        <img
                            [src]="'assets/images/flags/' + dataItem.flag"
                            alt="language-flag"
                            class="flag"
                            height="30"
                            width="30"
                        />
                        {{ dataItem.text }}
                    </ng-template>
                </assist-dropdownlist>
            </div>
        </div>
    </div>
</div>
