import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  OnInit,
  ElementRef,
  inject,
} from '@angular/core';
import { LoadingComponent } from '@cue/loading';
import { ProgressiveImageFallbackDirective } from '../../directives/progressive-image-fallback.directive';
import { ProgressiveImagePlaceholderDirective } from '../../directives/progressive-image-placeholder.directive';
import { ProgressiveImageDirective } from '../../directives/progressive-image.directive';
import { ProgressiveImageComponent } from '../progressive-image/progressive-image.component';
import { ImageDimensionsPipe } from '@cue/utilities';

@Component({
  selector: 'cue-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LoadingComponent,
    CommonModule,
    ProgressiveImageDirective,
    ProgressiveImageFallbackDirective,
    ProgressiveImagePlaceholderDirective,
    ProgressiveImageComponent,
    ImageDimensionsPipe,
  ],
})
export class ImageComponent implements OnInit, AfterViewInit {
  @Input() src = null;
  @Input() imageClass = '';
  @Input() loadWidth?: number | 'auto';
  @Input() loadHeight?: number;
  @Input() imgHeight = 'auto';
  @Input() imgWidth = '100%';
  @Input() imgMaxHeight;
  @Input() imgMaxWidth = '50px';

  private readonly elRef = inject(ElementRef);

  width?: number;

  ngOnInit() {
    if (this.loadWidth !== 'auto' && this.loadWidth) {
      this.width = this.loadWidth;
    }
  }

  ngAfterViewInit() {
    if (this.loadWidth === 'auto') {
      this.width = this.elRef.nativeElement.clientWidth;
    }
  }
}
