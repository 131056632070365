import { ElementRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export type ImageWidth = number;

@Pipe({
  name: 'imageWidth',
  standalone: true,
})
export class ImageWidthPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef<HTMLElement>,
  ) {}

  transform(url: any, options: ImageWidth | 'auto' | { width?: ImageWidth }): any {
    if (!url) return url;
    const isLocalRelativeUrl = url.startsWith('/') || url.startsWith('assets');
    if (isLocalRelativeUrl) {
      const finalLocalUrl = !url.startsWith('assets') ? url : `/${url}`;
      return this.sanitizer.bypassSecurityTrustUrl(
        new URL(`${location.origin}${finalLocalUrl}`).toString(),
      );
    }
    // is options typeof string?
    const isString = typeof options === 'string';

    const width = isString
      ? this.elementRef.nativeElement.clientWidth
      : typeof options === 'number'
        ? options
        : (
            options as unknown as {
              width: number;
            }
          ).width;
    const finalUrl = new URL(url);
    finalUrl.searchParams.append('width', width.toString());
    const extension = finalUrl.pathname.split('.').pop();
    if (extension !== 'svg') {
      finalUrl.searchParams.append('format', 'webp');
    }
    return this.sanitizer.bypassSecurityTrustUrl(finalUrl.toString());
  }
}
