import { createAction, props } from '@ngrx/store';

export const setAuthCode = createAction('[Account] Load Auth Code', props<{ authCode: string }>());

export const loadTokenRefreshToken = createAction(
  '[Account] Load TokenRefreshToken',
  props<{ route: boolean }>(),
);

export const tokenWithRefreshTokenSaved = createAction('[Account] TokenRefreshToken Saved');

export const authCodeReceived = createAction('[Account] MS Auth Code Received');

export const confirmAuthCodeReceived = createAction('[Account] Confirm Auth Code Received');
