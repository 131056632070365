import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap, filter } from 'rxjs/operators';

import * as ColleagueActions from '../actions/colleague.actions';
import { forkJoin } from 'rxjs';
import { CalendarsServiceFactoryService } from '@cue/calendars';
// Zde nutny kvuli url na image, skladanemu
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../services';

@Injectable()
export class ColleagueEffects {
  loadColeague$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ColleagueActions.loadColleague),
      filter((a) => a != null && a.identifier != null),
      switchMap((action) =>
        forkJoin([
          this.calendarImplementation$.pipe(
            switchMap((implementation) => implementation.getCollegueInfo(action.identifier)),
          ),
          this.calendarImplementation$.pipe(
            switchMap((implementation) => implementation.getImageUrl(action.identifier)),
            map((url) => this.sanitizer.bypassSecurityTrustUrl(url)),
          ),
        ]),
      ),
      map(([colleague, safeurl]) =>
        ColleagueActions.colleagueLoaded({
          colleague: { ...colleague, base64Image: safeurl },
        }),
      ),
    ),
  );

  calendarImplementation$ = this.calendarsServiceFactory.getImplementation(
    this.authService.getMode(),
  );

  constructor(
    private sanitizer: DomSanitizer,
    private actions$: Actions,
    private authService: AuthService,
    private calendarsServiceFactory: CalendarsServiceFactoryService,
  ) {}
}
