import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from '@cue/assist/ui/inputs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

export interface TreeItem {
  open: boolean;
  key: number;
  name: string;
  selected: boolean;
  children: TreeItem[];
}

@Component({
  selector: 'assist-area-tree',
  templateUrl: './area-tree.component.html',
  styleUrls: ['./area-tree.component.scss'],
  standalone: true,
  imports: [CheckboxComponent, CommonModule, FontAwesomeModule],
})
export class AreaTreeComponent {
  @Input() openAll = false;
  @Output() selectionChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  faCaretRight = faCaretRight;
  faCaretDown = faCaretDown;

  get data(): any[] {
    return this._data;
  }

  @Input() set data(value: any[]) {
    this._data = this.buildTree(value);
  }

  _data = [];
  _selection: any[] = [];
  get selection(): any {
    return this._selection;
  }

  key = 'id';
  name = 'name';

  @Input() set selection(value: any[]) {
    this._selection = value;
    if (this.data.length > 0) {
      this.setSelection(this._selection, this._data[0]);
    }
  }

  setSelection(selection: any[], value: any) {
    value.selected = selection.includes(value[this.key]);
    value.children.forEach((child) => this.setSelection(selection, child));
  }

  constructor() {}

  childSelected(level: TreeItem, toplevel = true): boolean {
    if (level.selected) return false;
    const selectedFromChildren = level.children.some((x) => x.selected);
    if (selectedFromChildren) return true;

    return level.children.some((x) => this.childSelected(x));
  }

  buildTree(value: any[]): TreeItem[] {
    const result = [];
    value.forEach((val) => {
      const newLevel: TreeItem = {
        selected: this.selection.includes(val[this.key]),
        [this.key]: val[this.key],
        key: val[this.key],
        name: val[this.name],
        open: this.openAll,
        children: this.buildTree(val.children),
      };
      result.push(newLevel);
    });
    return result;
  }

  onSelect(level: TreeItem, newValue: boolean, top: boolean = true) {
    if (newValue === true) {
      if (!this._selection.includes(level.key)) {
        this._selection = [...this._selection, level.key];
      }
    } else {
      const foundIndex = this._selection.indexOf(level.key);
      if (foundIndex !== -1) {
        this._selection = [...this._selection.filter((x) => x != level.key)];
      }
    }
    level.selected = newValue;
    level.children.forEach((ch) => this.onSelect(ch, newValue, false));

    if (top) this.selectionChanged.emit(this.selection);
  }
}
