<kendo-dropdownlist
    [data]="data"
    (focusin)="focus()"
    (focusout)="blur()"
    (closed)="closed.emit($event)"
    [formControl]="$any(_control)"
    [filterable]="filterable"
    [value]="value"
    [textField]="textField"
    [valueField]="valueField"
    (valueChange)="valueChange.emit($event)"
    (filterChange)="filterChange.emit($event)"
    [valuePrimitive]="valuePrimitive"
    [popupSettings]="popupSettings"
    [adaptiveMode]="adaptiveMode"
    [listHeight]="listHeight"
>
    <ng-container *ngIf="valueTemplate">
        <ng-template kendoDropDownListValueTemplate let-item>
            <ng-container
                [ngTemplateOutlet]="valueTemplate"
                [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="itemTemplate">
        <ng-template kendoDropDownListItemTemplate let-item>
            <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{ $implicit: item }"
            ></ng-container>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="fixedGroupTemplate">
        <ng-container *ngFor="let x of fixedGroupTemplate.toArray()">
            <ng-template kendoDropDownListFixedGroupTemplate let-group>
                <ng-container
                    [ngTemplateOutlet]="x"
                    [ngTemplateOutletContext]="{ $implicit: group }"
                ></ng-container>
            </ng-template>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="groupTemplate">
        <ng-template kendoDropDownListGroupTemplate let-group>
            <ng-container
                [ngTemplateOutlet]="groupTemplate"
                [ngTemplateOutletContext]="{ $implicit: group }"
            ></ng-container>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="footerTemplate">
        <ng-template kendoDropDownListFooterTemplate let-group>
            <ng-container
                [ngTemplateOutlet]="footerTemplate"
                [ngTemplateOutletContext]="{ $implicit: group }"
            ></ng-container>
        </ng-template>
    </ng-container>
</kendo-dropdownlist>
