import { Component, OnInit } from '@angular/core';
import { ReservationActions } from '../ngrx/actions';
import MessageRead = Office.MessageRead;
import AppointmentCompose = Office.AppointmentCompose;
import AppointmentRead = Office.AppointmentRead;
import { Store } from '@ngrx/store';
import * as fromModule from '../ngrx/reducers';
import { Router } from '@angular/router';

@Component({
  selector: 'addin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [],
})
export class DashboardComponent {
  constructor(
    private store: Store<fromModule.State>,
    private router: Router,
  ) {
    Office.onReady().then((x) => {
      this.performOutlookAction();
    });
  }

  private performOutlookAction() {
    const itemType = Office.context.mailbox.item.itemType as Office.MailboxEnums.ItemType;
    if (itemType == Office.MailboxEnums.ItemType.Message) {
      const itemData = Office.context.mailbox.item as MessageRead;
      console.log(itemData);
      const itemDataEdit2 = Office.context.mailbox.item as AppointmentCompose;
      itemDataEdit2.getItemIdAsync((x) => {
        this.store.dispatch(ReservationActions.loadEventDetailFromGraph({ itemId: x.value }));
      });
    } else if (itemType == Office.MailboxEnums.ItemType.Appointment) {
      const itemData = Office.context.mailbox.item as AppointmentRead;
      console.log(itemData);

      if (itemData.itemId) {
        this.store.dispatch(
          ReservationActions.loadEventDetailFromGraph({ itemId: itemData.itemId }),
        );
      } else {
        const itemDataEdit = Office.context.mailbox.item as AppointmentCompose;
        itemDataEdit.getItemIdAsync((x) => {
          if (!x.error) {
            this.store.dispatch(ReservationActions.loadEventDetailFromGraph({ itemId: x.value }));
          } else {
            this.router.navigateByUrl('reservations', { skipLocationChange: true }).then(() => {});
          }
        });
      }
    }
  }
}
