<div class="wrapper">
    <div
        class="accordion"
        [class.expanded]="isItemExpanded(i)"
        *ngFor="let resource of resourceList; let i = index"
    >
        <div class="accordion-header" (click)="expandShrinkGroup(i)">
            <div class="first-part">
                <p class="heading">
                    {{ resource.resourceDisplayNameForApp ?? resource.displayName }}
                </p>
                <ng-container [ngSwitch]="resource.responseStatus">
                    <span class="accepted" *ngSwitchCase="'accepted'">{{
                        translation.common.accepted | transloco
                    }}</span>
                    <span class="canceled" *ngSwitchCase="'declined'">{{
                        translation.common.cancelled | transloco
                    }}</span>
                    <span class="tentative" *ngSwitchCase="'tentative'">{{
                        translation.common.tentative | transloco
                    }}</span>
                </ng-container>
            </div>
            <div class="end-part">
                <div class="arrow">
                    <fa-icon [icon]="ICONS.faChevronDown"></fa-icon>
                </div>
            </div>
        </div>
        <div class="accordion-content" *ngIf="isItemExpanded(i)">
            <addin-resource-detail [resource]="resource"> </addin-resource-detail>
        </div>
    </div>
</div>
