import { createReducer, on } from '@ngrx/store';
import {
  IReservationDetail,
  IReservationItem,
  OutlookReservationMode,
} from '@outlook-addin/cue-http';
import { ReservationActions } from '../actions';

export type State = {
  reservationOutlookMode: OutlookReservationMode;
  reservationList: IReservationItem[];
  reservationDetail: IReservationDetail;
  reservationError: string | null;
  eventDetail?: any;
  isLoading: boolean;
};

export const initialState: State = {
  reservationOutlookMode: OutlookReservationMode.NotReservationDetail,
  reservationList: [],
  reservationDetail: null,
  reservationError: null,
  isLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(ReservationActions.loadReservationList, (state) => ({
    ...state,
    reservationList: [],
    isLoading: true,
  })),
  on(ReservationActions.reservationListLoaded, (state, action) => ({
    ...state,
    reservationList: action.reservationList,
    reservationDetail: null,
    isLoading: false,
  })),
  on(ReservationActions.loadReservationDetail, (state, { eventDetail }) => ({
    ...state,
    eventDetail: eventDetail,
    reservationDetail: null,
  })),
  on(ReservationActions.refreshReservationDetail, (state) => ({
    ...state,
    reservationDetail: null,
  })),
  on(ReservationActions.reservationDetailLoadSuccess, (state, action) => ({
    ...state,
    reservationDetail: action.detail,
    reservationError: null,
  })),
  on(ReservationActions.reservationDetailLoadFailed, (state, action) => ({
    ...state,
    reservationDetail: null,
    reservationError: action.error,
  })),
  on(ReservationActions.outlookReservationModeChange, (state, action) => ({
    ...state,
    reservationOutlookMode: action.reservationMode,
  })),
);
