import { createAction, props } from '@ngrx/store';
import {
  IAreaDetail,
  IPropertyFilter,
  IResourceFilterUpdateCrate,
  IResourceListItem,
  Requirement,
} from '@outlook-addin/cue-http';
import { NavigationDetailInfo } from '@assist/navigation/services';

export const searchTextChanged = createAction(
  '[Resources] Search text changed',
  props<{
    searchText: string;
  }>(),
);

export const resetRequirements = createAction('[Resources] Reset');

export const loadResourcesFilter = createAction('[Resources] Load Filter');
export const resourcesFilterLoaded = createAction(
  '[Resources] Filter Loaded',
  props<{ filter: IPropertyFilter[] }>(),
);

export const loadAreas = createAction('[Areas] Load');
export const areasLoaded = createAction('[Areas] Loaded', props<{ areas: IAreaDetail[] }>());

export const updateOnlyAvailableFilter = createAction(
  '[Resources] Update Only Available filter',
  props<{
    onlyAvailable: boolean;
  }>(),
);

export const updateStartEndFilter = createAction(
  '[Resources] Update Start and ENd',
  props<{
    start: Date;
    end: Date;
  }>(),
);

export const updateResourceFilter = createAction(
  '[Resources] Update Filter',
  props<{
    filterUpdate: IResourceFilterUpdateCrate;
    requirements: Requirement[];
  }>(),
);

export const loadResourceList = createAction('[Resources] Load ResourceList');

export const resourceListLoadSuccess = createAction(
  '[Resources] ResourceList Load Success',
  props<{ resourceList: IResourceListItem[] }>(),
);

export const resourceListLoadFailed = createAction(
  '[Resources] ResourceList Load Failed',
  props<{ error: string }>(),
);

export const loadResourceDetail = createAction(
  '[Resources] Load ResourceDetail',
  props<{ resourceId: number }>(),
);

export const resourceDetailLoadSuccess = createAction(
  '[Resources] ResourceDetail Load Success',
  props<{ resourceDetail: NavigationDetailInfo }>(),
);

export const resourceDetailLoadFailed = createAction(
  '[Resources] ResourceDetail Load Failed',
  props<{ error: string }>(),
);
