import { createAction, props } from '@ngrx/store';
import { FilterAttendee, Schedule } from '../models';

export const refreshSchedules = createAction('[Schedules] Refresh');

export const schedulesLoaded = createAction(
  '[Schedules] Loaded',
  props<{ schedules: Schedule[]; attendees: FilterAttendee[] }>(),
);

// Floorplan schedules

export const refreshFloorplanSchedules = createAction('[Floorplan Schedules] Refresh');

export const floorplanSchedulesLoaded = createAction(
  '[Floorplan Schedules] Loaded',
  props<{ schedules: Schedule[] }>(),
);

// Quick schedules

export const refreshQuickSchedules = createAction('[Quick Schedules] Refresh');

export const quickSchedulesLoaded = createAction(
  '[Quick Schedules] Loaded',
  props<{ schedules: Schedule[] }>(),
);

export const loadFavourites = createAction('[Favourites] Load');
export const favouritesLoaded = createAction(
  '[Favourites] Loaded',
  props<{ schedules: Schedule[]; start: Date }>(),
);
