import { Injectable, NgZone } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthHttpService, OutlookLoginMode, STORAGE_KEYS } from '@outlook-addin/cue-http';
import { AddinConfigService } from '@outlook-addin/shared';
import { AccountActions } from '../../ngrx/actions';
import { Store } from '@ngrx/store';
import * as fromModule from '../reducers';
import {
  forkJoin,
  fromEvent,
  interval,
  map,
  mapTo,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { AppStateModel } from '../../ngrx/reducers';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppStateModel>,
    private authService: AuthHttpService,
    private configService: AddinConfigService,
    private zone: NgZone,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem(STORAGE_KEYS.code)) {
      //this.authService.isUserLoggedIn()){
      return true;
    } else if (
      localStorage.getItem(STORAGE_KEYS.outlookLoginMode) == OutlookLoginMode.nonInteractive
    ) {
      return this.router.parseUrl('login');
    } else if (
      localStorage.getItem(STORAGE_KEYS.outlookLoginMode) == OutlookLoginMode.interactive
    ) {
      return this.router.parseUrl('login');
    } else {
      return this.processPopupLogin();
    }
    //
    // if(localStorage.getItem(STORAGE_KEYS.outlookLoginMode) != OutlookLoginMode.nonInteractive) {
    //   this.authService.setOutlookLoginMode(OutlookLoginMode.nonInteractive);
    //
    //   const popupWindow = window.open(`https://login.microsoftonline.com/${this.configService.value.configuration.tenantId}/oauth2/v2.0/authorize?client_id=${this.configService.value.configuration.clientId}
    //       &scope=Mail.Read offline_access openid profile User.Read&response_type=code&login_hint=${localStorage.getItem(STORAGE_KEYS.outlookUsername)}&prompt=none&redirect_uri=${`https://${window.location.host}/Authorize2`}`);
    //
    //   if (popupWindow) {
    //     const loadEvent$ = fromEvent(popupWindow, 'load');
    //     // const errorEvent$ = fromEvent(popupWindow, 'load');
    //
    //     loadEvent$.subscribe(x=>{
    //       const queryParams = new URLSearchParams(popupWindow.location.search);
    //       const code = queryParams.get('code');
    //
    //       if(code){
    //         return this.authCodeReceived(popupWindow,code)
    //       } else{
    //         console.error(x);
    //         // localStorage.setItem(STORAGE_KEYS.loginErrorMicrosoft, (x. .currentTarget as unknown as any).location.search);
    //         popupWindow.close();
    //         return this.router.parseUrl('login');
    //       }
    //     })
    //
    //     return loadEvent$
    //       .pipe(
    //         map((x) => {
    //
    //           const queryParams = new URLSearchParams(popupWindow.location.search);
    //           const code = queryParams.get('code');
    //
    //           if(code){
    //             return this.authCodeReceived(popupWindow,code)
    //           } else{
    //             console.error(x);
    //             // localStorage.setItem(STORAGE_KEYS.loginErrorMicrosoft, (x. .currentTarget as unknown as any).location.search);
    //             popupWindow.close();
    //             return this.router.parseUrl('login');
    //           }
    //         })
    //       );
    //   } else {
    //     localStorage.setItem(STORAGE_KEYS.loginErrorMicrosoft, 'CANNOT OPEN LOGIN WINDOW');
    //     return this.router.parseUrl('login');
    //   }
    // } else{
    //   return this.router.parseUrl('login');
    // }
  }

  private processPopupLogin(): Observable<boolean | UrlTree> {
    const popupWindow =
      window.open(`https://login.microsoftonline.com/${this.configService.value.configuration.tenantId}/oauth2/v2.0/authorize?client_id=${this.configService.value.configuration.clientId}
          &scope=Mail.Read offline_access openid profile User.Read&response_type=code&login_hint=${localStorage.getItem(STORAGE_KEYS.outlookUsername)}&prompt=none&redirect_uri=${`https://${window.location.host}/Authorize2`}`);
    const message$ = interval(1000).pipe(
      map(() => {
        return popupWindow.location.host;
      }),
      filter((x) => {
        return x == window.location.host;
      }),
      tap((x) => console.log(x)),
      take(1),
    );
    const popup$ = of(popupWindow);
    this.authService.setOutlookLoginMode(OutlookLoginMode.nonInteractive);

    return forkJoin([popup$, message$]).pipe(
      switchMap(([popupWindow, message]) => {
        if (popupWindow) {
          const queryParams = new URLSearchParams(popupWindow.location.search);
          const code = queryParams.get('code');

          if (code) {
            localStorage.setItem(STORAGE_KEYS.code, code);
            this.zone.run(() => {
              this.store.dispatch(AccountActions.loadTokenRefreshToken({ route: false }));
            });
            popupWindow.close();
            return interval(500).pipe(
              filter((x) => this.authService.accessToken != null),
              take(1),
              mapTo(this.router.parseUrl('main-start')),
            );
          } else {
            popupWindow.close();
            return of(this.router.parseUrl('login'));
          }
        } else {
          return of(this.router.parseUrl('login'));
        }
      }),
    );

    // if (popupWindow) {
    //   const loadEvent$ = fromEvent(popupWindow, 'load');
    //   // const errorEvent$ = fromEvent(popupWindow, 'load');
    //
    //   loadEvent$.subscribe(x=>{
    //     const queryParams = new URLSearchParams(popupWindow.location.search);
    //     const code = queryParams.get('code');
    //
    //     if(code){
    //       return this.authCodeReceived(popupWindow,code)
    //     } else{
    //       console.error(x);
    //       // localStorage.setItem(STORAGE_KEYS.loginErrorMicrosoft, (x. .currentTarget as unknown as any).location.search);
    //       popupWindow.close();
    //       return this.router.parseUrl('login');
    //     }
    //   })
    //
    //   return loadEvent$
    //     .pipe(
    //       map((x) => {
    //
    //         const queryParams = new URLSearchParams(popupWindow.location.search);
    //         const code = queryParams.get('code');
    //
    //         if(code){
    //           return this.authCodeReceived(popupWindow,code)
    //         } else{
    //           console.error(x);
    //           // localStorage.setItem(STORAGE_KEYS.loginErrorMicrosoft, (x. .currentTarget as unknown as any).location.search);
    //           popupWindow.close();
    //           return this.router.parseUrl('login');
    //         }
    //       })
    //     );
    // } else {
    //   localStorage.setItem(STORAGE_KEYS.loginErrorMicrosoft, 'CANNOT OPEN LOGIN WINDOW');
    //   return this.router.parseUrl('login');
    // }
  }

  private authCodeReceived(popupWindow: Window, code: string) {
    localStorage.setItem(STORAGE_KEYS.code, code);
    this.zone.run(() => {
      this.store.dispatch(AccountActions.loadTokenRefreshToken({ route: true }));
    });
    popupWindow.close();
    // return true;
    return this.router.parseUrl('main-start');
  }
}
