import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export type Imageheight = number;

@Pipe({
  name: 'imageHeight',
  standalone: true,
})
export class ImageHeightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: any, options: Imageheight | { height?: Imageheight }): any {
    if (!url) return url;
    const isLocalRelativeUrl = url.startsWith('/') || url.startsWith('assets');
    if (isLocalRelativeUrl) {
      const finalLocalUrl = !url.startsWith('assets') ? url : `/${url}`;
      return this.sanitizer.bypassSecurityTrustUrl(
        new URL(`${location.origin}${finalLocalUrl}`).toString(),
      );
    }

    const height = typeof options === 'number' ? options : options.height;
    const finalUrl = new URL(url);
    finalUrl.searchParams.append('height', height.toString());
    const extension = finalUrl.pathname.split('.').pop();
    if (extension !== 'svg') {
      finalUrl.searchParams.append('format', 'webp');
    }

    return this.sanitizer.bypassSecurityTrustUrl(finalUrl.toString());
  }
}
