import { Inject, Injectable } from '@angular/core';
import { catchError, map, noop, Observable, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiRequest } from './requests';
import { API_BASEURL } from '../api.provider';

@Injectable()
export class ApiService {
  constructor(
    @Inject(API_BASEURL) private _baseURL: string,
    private httpClient: HttpClient,
  ) {}

  getUrl(postfix: string) {
    return this._baseURL + postfix;
  }

  call<TRequest, TResponse>(request: ApiRequest<TRequest, TResponse>): Observable<TResponse> {
    const url = this.getUrl(request.url);

    const payload = request.payload;
    switch (request.method) {
      case 'get':
        return this.httpClient
          .get<TResponse>(url)
          .pipe(
            request.mapFn ? map(request.mapFn) : tap(noop),
            request.catchFn ? catchError((e) => of(request.catchFn(e))) : tap(noop),
          );
      case 'post':
        return this.httpClient
          .post<TResponse>(url, payload)
          .pipe(
            request.mapFn ? map(request.mapFn) : tap(noop),
            request.catchFn ? catchError((e) => of(request.catchFn(e))) : tap(noop),
          );
      case 'delete':
        return this.httpClient
          .delete<TResponse>(url)
          .pipe(
            request.mapFn ? map(request.mapFn) : tap(noop),
            request.catchFn ? catchError((e) => of(request.catchFn(e))) : tap(noop),
          );
      case 'put':
        return this.httpClient
          .put<TResponse>(url, payload)
          .pipe(
            request.mapFn ? map(request.mapFn) : tap(noop),
            request.catchFn ? catchError((e) => of(request.catchFn(e))) : tap(noop),
          );
      default:
        throw Error('Unknown http method');
    }
  }

  callObserveResponse<TRequest, TResponse>(request: ApiRequest<TRequest, TResponse>) {
    const url = this.getUrl(request.url);
    const payload = request.payload;
    switch (request.method) {
      case 'get':
        return this.httpClient.get<TResponse>(url, { observe: 'response' });
      case 'post':
        return this.httpClient.post<TResponse>(url, payload, { observe: 'response' });
      case 'delete':
        return this.httpClient.delete<TResponse>(url, { observe: 'response' });
      case 'put':
        return this.httpClient.put<TResponse>(url, payload, { observe: 'response' });
      default:
        throw Error('Unknown http method');
    }
  }
}
