import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ChoiseItem,
  IAdditionalFilterItem,
  IAreaDetail,
  IPropertyFilter,
  IResourceFilterUpdateCrate,
} from '@outlook-addin/cue-http';
import { Store } from '@ngrx/store';
import * as fromModule from '../ngrx/reducers';
import { faChevronUp, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { cueClose, cueAdd, IconComponent } from '@cue/ui/icons';
import { translation } from '@assist/shared/translations';
import {
  FormArray,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { HierarchyArea } from '@assist/shared/data';
import { ResourcesActions } from '../ngrx/actions';
import { v4 as uuidv4 } from 'uuid';
import { Memoize } from 'typescript-memoize';
import { realRequirements } from '../ngrx/reducers';
import { take } from 'rxjs/operators';
import { remove } from 'remove-accents';
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxComponent, TextboxComponent } from '@cue/assist/ui/inputs';
import { CommonModule } from '@angular/common';
import { MultiselectComponent } from '@cue/assist/ui/dropdowns';
import { RangeSliderComponent, RangeSliderModule } from '@progress/kendo-angular-inputs';
import { ImageWidthPipe } from '@cue/utilities';
import { AreaTreeComponent } from '@cue/assist/ui/area-tree';

export interface ReqItem {
  areaIds: number[];
  resourceTypeId: number;
  id: string;
  capacity: number[];
  filters: [];
}

@Component({
  selector: 'addin-resource-filter',
  templateUrl: './resource-filter.component.html',
  styleUrls: ['./resource-filter.component.scss'],
  standalone: true,
  imports: [
    PanelBarModule,
    TranslocoModule,
    IconComponent,
    FontAwesomeModule,
    TextboxComponent,
    CommonModule,
    MultiselectComponent,
    ReactiveFormsModule,
    RangeSliderModule,
    FormsModule,
    ImageWidthPipe,
    CheckboxComponent,
    AreaTreeComponent,
  ],
})
export class ResourceFilterComponent implements OnInit {
  ICONS = {
    faChevronUp,
    faMinusCircle,
    cueClose,
    cueAdd,
  };

  @Input() filterCount: number;
  @Input() currentFilter: IResourceFilterUpdateCrate;
  @Input() areas: IAreaDetail[] = [];
  @Input() hierarchyAreas: HierarchyArea[] = [];
  @Input() resourceFilters: IPropertyFilter[];
  @Output() refreshFilter: EventEmitter<any> = new EventEmitter<any>();

  protected readonly translation = translation;
  filterTexts = [];
  form: FormGroup<{
    requirements: FormArray<any>;
  }>;

  constructor(
    private store: Store<fromModule.State>,
    private fb: NonNullableFormBuilder,
  ) {
    this.form = fb.group({
      requirements: fb.array([]),
    });
  }

  private getValueAccordingDataTypeId(filter: IAdditionalFilterItem): any {
    switch (filter.dataTypeId) {
      case 1:
        return filter.valueBoolean;
      case 2:
        return [filter.valueNumberMin, filter.valueNumberMax];
      case 3:
        return [filter.valueDecimalMin, filter.valueDecimalMax];
      case 4:
        return filter.valueString;
      case 5:
        return filter.valueChoiceId;
      default:
        return null;
    }
  }

  private loadFilterForRequirement(
    resourceTypeId: number,
    filters: IAdditionalFilterItem[],
    reqId: string,
  ): any[] {
    const data = this.resourceFilters.find(
      (x) => x.resourceTypeId === resourceTypeId && x.resourceCount > 0,
    );

    const finalFilterList: any[] = data.addinPropertyDetails
      .filter((x) =>
        x.isFilterable && x.dataTypeId === 2
          ? x.valueNumberMax > x.valueNumberMin
          : x.dataTypeId === 3
            ? x.valueDecimalMax > x.valueDecimalMin
            : true,
      )
      .map((x) => x.id);
    for (let i = 0; i < finalFilterList.length; i++) {
      const d = finalFilterList[i];
      const foundFilterData = filters.filter((x) => x.id === d);

      if (foundFilterData.length === 1) {
        finalFilterList[i] = this.getValueAccordingDataTypeId(foundFilterData[0]);

        if (foundFilterData[0].dataTypeId === 2 || foundFilterData[0].dataTypeId === 3) {
          const currentReq = this.form.controls['requirements'].controls.filter(
            (x) => x.value.id === reqId,
          )[0];

          if (currentReq) {
            const data = (currentReq as unknown as any).controls['filters'].controls[i];
            if (data) {
              data.enable();
            }
          }
        }
        //enable if form control is slider
      } else {
        const dataItem = data.addinPropertyDetails.filter((x) => x.id === d)[0];

        finalFilterList[i] = null;
        if (dataItem.dataTypeId === 2) {
          finalFilterList[i] = [dataItem.valueNumberMin, dataItem.valueNumberMax];
        }
        if (dataItem.dataTypeId === 3) {
          finalFilterList[i] = [dataItem.valueDecimalMin, dataItem.valueDecimalMax];
        }
      }
    }

    return finalFilterList;
  }

  ngOnInit(): void {
    this.store
      .select(realRequirements)
      .pipe(take(1))
      .subscribe((requirements) => {
        requirements.forEach((requirement) => {
          const filteredAreas = this.areas.filter((area) => requirement.areaIds.includes(area.id));
          this.addResourceOfTypeId(
            {
              resourceTypeId: requirement.resourceTypeId,
              resourceTypeName: requirement.resourcePropertyName,
            },
            filteredAreas,
            false,
            requirement.id,
          );
        });

        const valueReq = requirements.map((x) => {
          return {
            ...x,
            name: x.resourcePropertyName,
            resourceTypeId: x.resourceTypeId,
            filters: this.loadFilterForRequirement(x.resourceTypeId, x.filters, x.id),
          };
        });

        this.form.patchValue(
          {
            requirements: valueReq,
          },
          { emitEvent: true },
        );
      });
  }

  search() {
    if (this.form.valid) {
      const reqControlList = this.form.controls.requirements.controls.filter((x) => x.enabled);
      const resultList: IAdditionalFilterItem[] = [];

      const requirementList = this.form.value.requirements.map((x) => ({
        id: x.id,
        resourceTypeId: x.resourceTypeId,
        areaIds: x.areaIds,
        capacity: x.capacity,
      }));
      for (let i = 0; i < reqControlList.length; i++) {
        const reqItem = reqControlList[i].value;
        const filterAdditionalData = this.resourceFilters.find(
          (x) => x.resourceTypeId === reqItem.resourceTypeId,
        );
        const filterAddDataFilterPropList = filterAdditionalData.addinPropertyDetails.filter((x) =>
          x.isFilterable && x.dataTypeId === 2
            ? x.valueNumberMax > x.valueNumberMin
            : x.dataTypeId === 3
              ? x.valueDecimalMax > x.valueDecimalMin
              : true,
        );

        const filtersFormArray: FormArray = (reqControlList[i] as unknown as any).controls[
          'filters'
        ];

        for (let x = 0; x < filtersFormArray.controls.length; x++) {
          const currentControl = filtersFormArray.controls[x];
          if (currentControl.enabled) {
            const filterData = currentControl.value;
            if (filterData) {
              resultList.push({
                id: filterAddDataFilterPropList[x].id,
                requirementId: reqItem.id,
                dataTypeId: filterAddDataFilterPropList[x].dataTypeId,
                valueNumberMin:
                  filterAddDataFilterPropList[x].dataTypeId == 2 ? filterData[0] : null,
                valueNumberMax:
                  filterAddDataFilterPropList[x].dataTypeId == 2 ? filterData[1] : null,
                valueDecimalMin:
                  filterAddDataFilterPropList[x].dataTypeId == 3 ? filterData[0] : null,
                valueDecimalMax:
                  filterAddDataFilterPropList[x].dataTypeId == 3 ? filterData[1] : null,
                valueBoolean: filterAddDataFilterPropList[x].dataTypeId == 1 ? filterData : null,
                valueString: filterAddDataFilterPropList[x].dataTypeId == 4 ? filterData : null,
                capacityMin: reqItem.capacity ? reqItem.capacity[0] : null,
                capacityMax: reqItem.capacity ? reqItem.capacity[1] : null,
                valueChoiceId:
                  filterAddDataFilterPropList[x].dataTypeId == 5
                    ? (filterData as unknown as any).map((x) => x)
                    : null,
              });
            }
          }
        }
      }

      this.store.dispatch(
        ResourcesActions.updateResourceFilter({
          requirements: requirementList,
          filterUpdate: {
            additionalFilterList: resultList,
          },
        }),
      );
      this.refreshFilter.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }

  removeRequirement(index: number) {
    (this.form.get('requirements') as FormArray).removeAt(index);
    this.form.markAsDirty();
  }

  isCapacityFilterableByResourceTypeId(typeId: number) {
    const rtype = this.getCapacityRangesByResourceTypeId(typeId);
    return rtype.max !== rtype.min;
  }

  getCapacityRangesByResourceTypeId(typeId: number) {
    const resourceType = this.resourceFilters.filter((x) => x.resourceTypeId === typeId)[0];
    return {
      min: resourceType.capacityMin,
      max: resourceType.capacityMax,
    };
  }

  getResourceFilterList() {
    return this.resourceFilters?.filter((x) => x.resourceCount > 0);
  }

  getFilterablFilterByResourceTypeId(typeId: number) {
    return this.resourceFilters
      .filter((x) => x.resourceTypeId === typeId && x.resourceCount > 0)[0]
      .addinPropertyDetails.filter(
        (x) =>
          x.isFilterable &&
          (x.dataTypeId === 2
            ? x.valueNumberMax > x.valueNumberMin
            : x.dataTypeId === 3
              ? x.valueDecimalMax > x.valueDecimalMin
              : true),
      );
  }

  @Memoize((choiceIdList, filterText) => {
    return choiceIdList.map((x) => x.id).join('_') + filterText;
  })
  getComboDataForFilterId(choiceIdList: ChoiseItem[], filterText: string) {
    return choiceIdList
      .map((x) => {
        return {
          id: x.id,
          name: x.name,
          imageUrl: x.imageUrl,
        };
      })
      .filter(
        (x) =>
          !filterText || remove(x.name).toLowerCase().includes(remove(filterText).toLowerCase()),
      );
  }

  setFilter(groupIndex: number, filterIndex: number, $event: string) {
    if (this.filterTexts[groupIndex] == null) {
      this.filterTexts[groupIndex] = [];
    }
    this.filterTexts[groupIndex][filterIndex] = $event;
  }

  getFilter(groupIndex: number, filterIndex: number) {
    if (this.filterTexts[groupIndex]?.[filterIndex]) {
      return this.filterTexts[groupIndex][filterIndex];
    }
    return null;
  }

  addResourceOfTypeId(
    data: {
      resourceTypeId: number;
      resourceTypeName: string;
    },
    areas: IAreaDetail[],
    emitEvent = true,
    reqId?: string,
  ) {
    const array = this.form.controls.requirements;
    const name = data.resourceTypeName;
    const tempFilters = this.getFilterablFilterByResourceTypeId(data.resourceTypeId);
    const ranges = this.getCapacityRangesByResourceTypeId(data.resourceTypeId)!;
    const capacity = this.isCapacityFilterableByResourceTypeId(data.resourceTypeId)
      ? [ranges.min, ranges.max]
      : null;
    const allAreaIds = areas.map((x) => x.id);
    array.push(
      this.fb.group({
        id: reqId ?? uuidv4(),
        typeName: [name],
        areaIds: [allAreaIds],
        typeId: [data.resourceTypeId],
        resourceTypeId: [data.resourceTypeId],
        name: [name],
        capacity: [capacity],
        filters: this.fb.array(
          tempFilters.map((x) => {
            return x.dataTypeId === 2
              ? this.fb.control({ value: [x.valueNumberMin, x.valueNumberMax], disabled: true })
              : x.dataTypeId === 3
                ? this.fb.control({ value: [x.valueDecimalMin, x.valueDecimalMax], disabled: true })
                : this.fb.control(null);
          }),
        ),
      }),
      {
        emitEvent: emitEvent,
      },
    );
    this.form.markAsDirty();
  }

  reset() {
    (this.form.get('requirements') as FormArray).clear({ emitEvent: false });

    this.form.setValue({
      requirements: [],
    });
    this.search();
  }

  resetFilters() {
    (this.form.get('requirements') as FormArray).clear({ emitEvent: false });
    this.form.setValue({
      requirements: [],
    });
  }

  enableDisableFormControl(formGroup: any, index: number, wantEnable: boolean) {
    const currentFormGroup = formGroup.controls['filters'].controls[index];
    if (wantEnable) {
      currentFormGroup.enable();
    } else {
      currentFormGroup.reset();
      currentFormGroup.disable();
    }
  }
}
