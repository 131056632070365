<div class="safe-padding filter-button">
    <button (click)="search()" class="small">
        <cue-icon [icon]="ICONS.cueClose"></cue-icon>
        {{ translation.outlookAddin.closeFilters | transloco }}
    </button>

    <button
        *ngIf="form.get('requirements').value.length > 0"
        (click)="resetFilters()"
        class="small error"
    >
        <cue-icon [icon]="ICONS.cueClose"></cue-icon>
        {{ translation.outlookAddin.resetFilters | transloco }}
    </button>

    <!--  <div class="remove-filters" *ngIf="filterCount > 0">-->
    <!--    {{ filterCount }}-->
    <!--    {{ translation.outlookAddin.activeFilterCount | transloco }}-->
    <!--    <cue-icon [icon]="cueClose" (click)="refreshFilter.emit()"></cue-icon>-->
    <!--  </div>-->
</div>

<div class="first-menu-wrapper">
    <div class="first-menu-inner">
        <kendo-panelbar [keepItemContent]="true">
            <kendo-panelbar-item
                *ngFor="
                    let requiredRoomFormGroup of $any(this.form.get('requirements')).controls;
                    let i = index
                "
                [expanded]="true"
                class="group-selector"
                title=""
            >
                <ng-template kendoPanelBarItemTitle>
                    <div class="group-name">
                        <h6>
                            <fa-icon [icon]="ICONS.faChevronUp" class="collapse"></fa-icon>
                            {{ requiredRoomFormGroup.value.typeName }}
                            {{ translation.leftMenu.group | transloco }}
                            {{ i + 1 }}
                        </h6>
                        <div class="icon-group">
                            <fa-icon
                                (click)="removeRequirement(i)"
                                [icon]="ICONS.faMinusCircle"
                                class="delete"
                            ></fa-icon>
                        </div>
                    </div>
                </ng-template>

                <ng-template kendoPanelBarContent>
                    <div class="group-wrapper">
                        <form [formGroup]="requiredRoomFormGroup" class="form">
                            <div
                                *ngIf="
                                    isCapacityFilterableByResourceTypeId(
                                        requiredRoomFormGroup.value.typeId
                                    )
                                "
                                class="form-group"
                            >
                                <label>{{ translation.leftMenu.capacity | transloco }}</label>
                                <kendo-rangeslider
                                    [max]="
                                        getCapacityRangesByResourceTypeId(
                                            requiredRoomFormGroup.value.typeId
                                        ).max
                                    "
                                    [min]="
                                        getCapacityRangesByResourceTypeId(
                                            requiredRoomFormGroup.value.typeId
                                        ).min
                                    "
                                    formControlName="capacity"
                                ></kendo-rangeslider>

                                <div class="min-max-wrapper">
                                    <div class="min-max-inner">
                                        <assist-textbox
                                            (ngModelChange)="
                                                requiredRoomFormGroup
                                                    .get('capacity')
                                                    .setValue([
                                                        $event,
                                                        requiredRoomFormGroup.get('capacity')
                                                            .value[1]
                                                    ])
                                            "
                                            [ngModelOptions]="{ standalone: true }"
                                            [ngModel]="
                                                requiredRoomFormGroup.get('capacity').value[0]
                                            "
                                            class="numeric"
                                        ></assist-textbox>
                                    </div>
                                    <div class="min-max-inner">
                                        <assist-textbox
                                            (ngModelChange)="
                                                requiredRoomFormGroup
                                                    .get('capacity')
                                                    .setValue([
                                                        requiredRoomFormGroup.get('capacity')
                                                            .value[0],
                                                        $event
                                                    ])
                                            "
                                            [ngModelOptions]="{ standalone: true }"
                                            [ngModel]="
                                                requiredRoomFormGroup.get('capacity').value[1]
                                            "
                                            class="numeric"
                                        ></assist-textbox>
                                    </div>
                                </div>
                            </div>
                            <!--                    ************************-->
                            <!--                    DYNAMICKY FILTRY START---->
                            <!--                    ************************-->
                            <div
                                *ngFor="
                                    let filter of getFilterablFilterByResourceTypeId(
                                        requiredRoomFormGroup.value.typeId
                                    );
                                    let filterIndex = index
                                "
                                class="form-group"
                            >
                                <ng-container [ngSwitch]="filter.dataTypeId">
                                    <!--      ARRAY FILTER TYPE START-->
                                    <ng-container *ngSwitchCase="5">
                                        <label>{{ filter.name }}</label>
                                        <assist-multiselect
                                            [filterable]="true"
                                            (filterChange)="setFilter(i, filterIndex, $event)"
                                            [data]="
                                                getComboDataForFilterId(
                                                    filter.choiceIds,
                                                    getFilter(i, filterIndex)
                                                )
                                            "
                                            [formControl]="
                                                requiredRoomFormGroup.get('filters.' + filterIndex)
                                            "
                                            [textField]="'name'"
                                            [valueField]="'id'"
                                            [valuePrimitive]="false"
                                            class="equipments form-control"
                                        >
                                            <ng-template let-dataItem tagTemplate>
                                                <div class="item-selected">
                                                    <span class="item-tescted-text">
                                                        <div class="image-wrapper">
                                                            <img
                                                                [src]="
                                                                    dataItem.imageUrl
                                                                        | imageWidth: 90
                                                                "
                                                            />
                                                        </div>

                                                        {{ dataItem.name }}
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template itemTemplate let-dataItem>
                                                <div class="row" style="width: 100%">
                                                    <span class="template icon-left col-md-12">
                                                        <span class="item-tescted-text">
                                                            <div class="image-wrapper">
                                                                <img
                                                                    [src]="
                                                                        dataItem.imageUrl
                                                                            | imageWidth: 90
                                                                    "
                                                                />
                                                            </div>
                                                            {{ dataItem.name }}
                                                        </span>
                                                    </span>
                                                </div>
                                            </ng-template>
                                        </assist-multiselect>
                                    </ng-container>
                                    <!--      STRING FILTER TYPE START-->
                                    <ng-container *ngSwitchCase="4">
                                        <label>{{ filter.name }}</label>
                                        <assist-textbox
                                            [showClear]="true"
                                            [formControl]="
                                                requiredRoomFormGroup.get('filters.' + filterIndex)
                                            "
                                        >
                                        </assist-textbox>
                                    </ng-container>
                                    <!--                          NUMBER TYPE START-->
                                    <ng-container *ngSwitchCase="2">
                                        <label
                                            >{{ filter.name }}
                                            <cue-icon
                                                *ngIf="
                                                    requiredRoomFormGroup.get(
                                                        'filters.' + filterIndex
                                                    ).enabled
                                                "
                                                [icon]="ICONS.cueClose"
                                                (click)="
                                                    enableDisableFormControl(
                                                        requiredRoomFormGroup,
                                                        filterIndex,
                                                        false
                                                    )
                                                "
                                                class="reset-filter-icon"
                                            ></cue-icon>
                                            <!--                      <cue-icon *ngIf="!(requiredRoomFormGroup.get('filters.' + filterIndex)).enabled" [icon]="cueCheck" (click)="enableDisableFormControl(requiredRoomFormGroup,filterIndex, true)" class="reset-filter-icon"></cue-icon>-->
                                        </label>
                                        <div
                                            (click)="
                                                enableDisableFormControl(
                                                    requiredRoomFormGroup,
                                                    filterIndex,
                                                    true
                                                )
                                            "
                                        >
                                            <kendo-rangeslider
                                                [formControl]="
                                                    requiredRoomFormGroup.get(
                                                        'filters.' + filterIndex
                                                    )
                                                "
                                                [max]="filter.valueNumberMax"
                                                [min]="filter.valueNumberMin"
                                            ></kendo-rangeslider>

                                            <div class="min-max-wrapper">
                                                <div class="min-max-inner">
                                                    <assist-textbox
                                                        (ngModelChange)="
                                                            requiredRoomFormGroup
                                                                .get('filters.' + filterIndex)
                                                                .setValue([
                                                                    $event,
                                                                    requiredRoomFormGroup.get(
                                                                        'filters.' + filterIndex
                                                                    ).value[1]
                                                                ])
                                                        "
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [ngModel]="
                                                            requiredRoomFormGroup.get(
                                                                'filters.' + filterIndex
                                                            ).value[0]
                                                        "
                                                        class="numeric"
                                                    ></assist-textbox>
                                                </div>
                                                <div class="min-max-inner">
                                                    <assist-textbox
                                                        (ngModelChange)="
                                                            requiredRoomFormGroup
                                                                .get('filters.' + filterIndex)
                                                                .setValue([
                                                                    requiredRoomFormGroup.get(
                                                                        'filters.' + filterIndex
                                                                    ).value[0],
                                                                    $event
                                                                ])
                                                        "
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [ngModel]="
                                                            requiredRoomFormGroup.get(
                                                                'filters.' + filterIndex
                                                            ).value[1]
                                                        "
                                                        class="numeric"
                                                    ></assist-textbox>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!--  DECIMAL  TYPE START-->
                                    <ng-container *ngSwitchCase="3">
                                        <label
                                            >{{ filter.name }}
                                            <cue-icon
                                                *ngIf="
                                                    requiredRoomFormGroup.get(
                                                        'filters.' + filterIndex
                                                    ).enabled
                                                "
                                                [icon]="ICONS.cueClose"
                                                (click)="
                                                    enableDisableFormControl(
                                                        requiredRoomFormGroup,
                                                        filterIndex,
                                                        false
                                                    )
                                                "
                                                class="reset-filter-icon"
                                            ></cue-icon>
                                            <!--                      <cue-icon *ngIf="!(requiredRoomFormGroup.get('filters.' + filterIndex)).enabled" [icon]="cueCheck" (click)="enableDisableFormControl(requiredRoomFormGroup,filterIndex, true)" class="reset-filter-icon"></cue-icon>-->
                                        </label>
                                        <div
                                            (click)="
                                                enableDisableFormControl(
                                                    requiredRoomFormGroup,
                                                    filterIndex,
                                                    true
                                                )
                                            "
                                        >
                                            <kendo-rangeslider
                                                [formControl]="
                                                    requiredRoomFormGroup.get(
                                                        'filters.' + filterIndex
                                                    )
                                                "
                                                [max]="filter.valueDecimalMax"
                                                [min]="filter.valueDecimalMin"
                                            ></kendo-rangeslider>

                                            <div class="min-max-wrapper">
                                                <div class="min-max-inner">
                                                    <assist-textbox
                                                        (ngModelChange)="
                                                            requiredRoomFormGroup
                                                                .get('filters.' + filterIndex)
                                                                .setValue([
                                                                    $event,
                                                                    requiredRoomFormGroup.get(
                                                                        'filters.' + filterIndex
                                                                    ).value[1]
                                                                ])
                                                        "
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [ngModel]="
                                                            requiredRoomFormGroup.get(
                                                                'filters.' + filterIndex
                                                            ).value[0]
                                                        "
                                                        class="numeric"
                                                    ></assist-textbox>
                                                </div>
                                                <div class="min-max-inner">
                                                    <assist-textbox
                                                        (ngModelChange)="
                                                            requiredRoomFormGroup
                                                                .get('filters.' + filterIndex)
                                                                .setValue([
                                                                    requiredRoomFormGroup.get(
                                                                        'filters.' + filterIndex
                                                                    ).value[0],
                                                                    $event
                                                                ])
                                                        "
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [ngModel]="
                                                            requiredRoomFormGroup.get(
                                                                'filters.' + filterIndex
                                                            ).value[1]
                                                        "
                                                        class="numeric"
                                                    ></assist-textbox>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!--  CHECKBOX  TYPE START-->
                                    <ng-container *ngSwitchCase="1">
                                        <label>{{ filter.name }}</label>
                                        <div class="kendo-switch-wrapper">
                                            <assist-checkbox
                                                [allowTripple]="true"
                                                [formControl]="
                                                    requiredRoomFormGroup.get(
                                                        'filters.' + filterIndex
                                                    )
                                                "
                                                class="form-control"
                                            ></assist-checkbox>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <label>{{ translation.leftMenu.area | transloco }}</label>
                            <assist-area-tree
                                (selectionChanged)="
                                    requiredRoomFormGroup.get('areaIds').setValue($event)
                                "
                                [selection]="requiredRoomFormGroup.get('areaIds').value"
                                [data]="hierarchyAreas"
                            ></assist-area-tree>
                        </form>
                    </div>
                </ng-template>
            </kendo-panelbar-item>
        </kendo-panelbar>

        <button
            type="button"
            *ngFor="let resourceType of getResourceFilterList()"
            class="secondary small"
            (click)="addResourceOfTypeId(resourceType, areas)"
        >
            <cue-icon [icon]="ICONS.cueAdd"></cue-icon>
            {{ resourceType.resourceTypeName }}
        </button>
    </div>
</div>
