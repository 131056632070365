<div class="content">
    <ng-container *ngIf="resourceDetail.loading">
        <cue-loading></cue-loading>
    </ng-container>
    <ng-container *ngIf="resourceDetail.error">
        <div class="header">
            <button class="link secondary" (click)="closeResourceDetail.emit()">
                <cue-icon [icon]="ICONS.cueClose"></cue-icon>
            </button>
        </div>
        {{ translation.outlookAddin.errorDescription | transloco }}
        <button (click)="refresh.emit()" class="small">
            <cue-icon [icon]="ICONS.cueRefresh"></cue-icon>
            {{ translation.outlookAddin.refresh | transloco }}
        </button>
    </ng-container>
    <ng-container *ngIf="!resourceDetail.loading && !resourceDetail.error">
        <div class="header">
            <h2>
                {{ resourceDetail.data?.displayNameForApp ?? resourceDetail.data?.name }}
            </h2>
            <button class="link secondary" (click)="closeResourceDetail.emit()">
                <cue-icon [icon]="ICONS.cueClose"></cue-icon>
            </button>
        </div>
        <div class="detail-wrapper">
            <div>
                <div
                    class="assigned-to-label"
                    *ngIf="
                        resourceDetail.data?.isAssignedTo &&
                        resourceDetail.data?.isAssignedTo != 'free'
                    "
                >
                    <cue-icon [icon]="resourceDetail.data!.isAssignedTo | assignTo"></cue-icon>
                    {{ resourceDetail.data!.isAssignedTo | assignToTranslateKey | transloco }}
                </div>
                <button
                    *ngIf="resourceDetail.data?.resourceGuid | resourceExternalUrl as resourceUrl"
                    class="link secondary"
                    (click)="navigateToResourceDetailInAssist(resourceUrl)"
                >
                    <cue-icon
                        [icon]="ICONS.cueExternalLink"
                        [title]="translation.outlookAddin.cueExternalLink | transloco"
                    ></cue-icon>
                    Open in Assist
                </button>
            </div>

            <div class="room-info">
                <table *ngIf="resourceDetail">
                    <tr *ngIf="resourceDetail.data?.displayNameForApp && resourceDetail.data?.name">
                        <th>
                            <div>
                                {{ translation.leftMenu.name | transloco }}
                            </div>
                        </th>
                        <td>{{ resourceDetail.data?.name }}</td>
                    </tr>
                    <tr>
                        <th>
                            <div>
                                {{ translation.resourceDetail.type | transloco }}
                            </div>
                        </th>
                        <td>{{ resourceDetail.data?.resourceTypeName }}</td>
                    </tr>
                    <tr>
                        <th>
                            <div>
                                {{ translation.resourceDetail.capacity | transloco }}
                            </div>
                        </th>
                        <td>{{ resourceDetail.data?.capacity }}</td>
                    </tr>
                    <tr
                        *ngFor="
                            let simpleFilter of getSimpleFiltersByResourceTypeId(
                                $any(resourceDetail?.data)
                            )
                        "
                    >
                        <th>
                            <div>
                                {{ simpleFilter.name }}
                            </div>
                        </th>
                        <td>
                            <ng-container *ngIf="simpleFilter.value === true">
                                <fa-icon [icon]="ICONS.faCheck" class="correct"></fa-icon>
                            </ng-container>

                            <ng-container *ngIf="simpleFilter.value === false">
                                <fa-icon [icon]="ICONS.faTimes" class="incorrect"></fa-icon>
                            </ng-container>

                            <ng-container
                                *ngIf="simpleFilter.value !== true && simpleFilter.value !== false"
                            >
                                {{ simpleFilter.value }}
                            </ng-container>
                        </td>
                    </tr>
                </table>
                <div class="content-centerer-inner" *ngIf="resourceDetail?.data">
                    <ul
                        *ngFor="
                            let enumerableFilter of getEnumerableFiltersByResourceTypeId(
                                $any(resourceDetail?.data)
                            )
                        "
                        class="room-properties"
                    >
                        <div class="item-label">
                            {{ enumerableFilter.name }}
                        </div>
                        <li *ngFor="let value of enumerableFilter.value">
                            <div class="img-wrapper">
                                <img [src]="value.imageUrl | imageWidth: 90" />
                            </div>
                            <span class="descriptionText">
                                {{ value.title }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="breadcrumb-container">
                <span
                    class="breadcrumb"
                    *ngFor="let item of resourceDetail.data?.location; let i = index"
                >
                    <ng-container *ngIf="i !== 0">
                        <cue-icon [icon]="ICONS.cueArrowRight"></cue-icon>
                    </ng-container>
                    <span class="part">{{ item }}</span>
                </span>
            </div>
            <addin-resource-map
                [geoData]="resourceDetail.data.mapGeoData"
                [imageSrc]="resourceDetail.data.mapUrl"
                [resourceId]="resourceDetail.data.resourceGuid"
            ></addin-resource-map>

            <div class="row">
                <div class="col-md-12" style="text-align: center">
                    <cue-image
                        [loadWidth]="400"
                        [imageClass]="'card-img-top'"
                        [imgHeight]="'180px'"
                        [imgMaxWidth]="'438px'"
                        [imgWidth]="'100%'"
                        [src]="
                            currentImage
                                ? currentImage
                                : resourceDetail.data?.files.length > 0
                                  ? resourceDetail.data?.files[0]
                                  : null
                        "
                    >
                    </cue-image>
                    <div class="imgThumbsCont">
                        <cue-image
                            (click)="currentImage = image"
                            *ngFor="let image of resourceDetail.data?.files"
                            [loadWidth]="70"
                            [imageClass]="'card-img-top'"
                            [imgHeight]="'40px'"
                            [imgMaxWidth]="'70px'"
                            [imgWidth]="'70px'"
                            [src]="image"
                        ></cue-image>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <button
                [disabled]="resourceDetail.data?.isAssignedTo === resourceAssignType.Restricted"
                class="small"
                [class.error]="isResourceSelected"
                (click)="selectDeselectResource.emit(resourceDetail.data?.resourceId)"
            >
                <ng-container *ngIf="!isResourceSelected">
                    <cue-icon [icon]="ICONS.cueAction"></cue-icon>
                    {{ translation.outlookAddin.chooseResource | transloco }}
                </ng-container>
                <ng-container *ngIf="isResourceSelected">
                    <cue-icon [icon]="ICONS.cueClose"></cue-icon>
                    {{ translation.outlookAddin.removeResource | transloco }}
                </ng-container>
            </button>
        </div>
    </ng-container>
</div>
